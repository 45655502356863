/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useSanctionAssetsStore = defineStore("sanctionassets", {
  actions: {
    async sanctionaListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "serialNumber:contains": payload.serialNumber,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/sanction-asset/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionaInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/sanction-asset/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionaAddFetch(payload) {
      const body = {
        serialNumber: payload.serialNumber,
        categoryUid: payload.categoryUid,
        docNumber: payload.docNumber,
        docDate: payload.docDate,
        caseNumber: payload.caseNumber,
        source: payload.source,
        flawMark: payload.flawMark,
        remark: payload.remark,
        reportBy: payload.reportBy,
        reportDate: payload.reportDate,
        refDocUid: payload.refDocUid,
        status: payload.status,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/sanction-asset/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionaUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        serialNumber: payload.serialNumber,
        categoryUid: payload.categoryUid,
        docNumber: payload.docNumber,
        docDate: payload.docDate,
        caseNumber: payload.caseNumber,
        source: payload.source,
        flawMark: payload.flawMark,
        remark: payload.remark,
        reportBy: payload.reportBy,
        reportDate: payload.reportDate,
        refDocUid: payload.refDocUid,
        status: payload.status,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/sanction-asset/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async sanctionaDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/sanction-asset/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionaPlatformListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "serialNumber:contains": payload.serialNumber,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/platform/sanction-asset/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
