/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useShopStore = defineStore("shop", {
  actions: {
    async shopListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "nameTh:contains": "",
          "nameEn:contains": "",
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopInfoFromSessionFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/info";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopAddFetch(payload) {
      const body = {
        // uid: "564650609597220734",
        // groupUid: "562312503437746477",
        // code: "KL",
        // nameTh: "คลองหลวง",
        // nameEn: "Khlongluang",
        // subGroup: null,
        // subDomain: "dev-kl.nvtx.tech",
        // effectiveDate: "2024-04-25",
        // endDate: null,
        // contactPerson: "How-Platform",
        // contactNumber: "+66819999988",
        // contactEmail: "how@nvtx.tech",
        // wfType: "WF_TYPE2",
        // boStatus: "Active",
        // foStatus: "Active",
        // businessDay: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
        // version: "3",

        uid: payload.uid,
        groupUid: payload.groupUid,
        code: payload.code,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        subGroup: null,
        subDomain: payload.subGroup,
        effectiveDate: payload.effectiveDate,
        endDate: null,
        contactPerson: payload.contactPerson,
        contactNumber: payload.contactNumber,
        contactEmail: payload.contactEmail,
        wfType: payload.wfType,
        boStatus: payload.boStatus,
        foStatus: payload.foStatus,
        businessDay: payload.businessDay,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopUpdateFetch(payload) {
      const body = {
        // uid: "564650609597220734",
        // groupUid: "562312503437746477",
        // code: "KL",
        // nameTh: "คลองหลวง",
        // nameEn: "Khlongluang",
        // subGroup: null,
        // subDomain: "dev-kl.nvtx.tech",
        // effectiveDate: "2024-04-25",
        // endDate: null,
        // contactPerson: "How-Platform",
        // contactNumber: "+66819999988",
        // contactEmail: "how@nvtx.tech",
        // wfType: "WF_TYPE2",
        // boStatus: "ACTIVE",
        // foStatus: "ACTIVE",
        // businessDay: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
        // version: "6",

        uid: payload.uid,
        groupUid: payload.groupUid,
        code: payload.code,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        subGroup: null,
        subDomain: payload.subGroup,
        effectiveDate: payload.effectiveDate,
        endDate: null,
        contactPerson: payload.contactPerson,
        contactNumber: payload.contactNumber,
        contactEmail: payload.contactEmail,
        wfType: payload.wfType,
        boStatus: payload.boStatus,
        foStatus: payload.foStatus,
        businessDay: payload.businessDay,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
