/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const usePromotiontStore = defineStore("promotion", {
  actions: {
    async promotionListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          intUid: payload.intUid,
          cusTypeUid: payload.cusTypeUid,
          // "startDate:le": payload.startDate,
          "startDate:ge": payload.startDate,
          "endDate:le": payload.endDate,
          // "endDate:ge": payload.endDate,
        },
      };

      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/promotion/list";
          const response = http.post(pathUrl, body);
          // console.log("body : " + JSON.stringify(body));
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/promotion/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        intUid: payload.intUid,
        cusTypeUid: payload.cusTypeUid,
        description: payload.description,
        startDate: payload.startDate,
        endDate: payload.endDate,
        duration: payload.duration,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/promotion/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        intUid: payload.intUid,
        cusTypeUid: payload.cusTypeUid,
        description: payload.description,
        startDate: payload.startDate,
        endDate: payload.endDate,
        duration: payload.duration,
        intStatus: payload.intStatus,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/promotion/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionApproveFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/promotion/approve/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionRevertFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/promotion/revert/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionRevokeFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/promotion/revoke/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async promotionDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/promotion/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
