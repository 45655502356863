<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12 ">

        <div class="card mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.millegal') }} ({{ $t('content.namepf') }})</h5>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.serial') }}</th>
                      <th>{{ $t('content.refer') }}</th>
                      <th>{{ $t('content.docno') }}</th>
                      <th>{{ $t('content.docdate') }}</th>
                      <th>{{ $t('content.criminal') }}</th>
                      <th>{{ $t('content.agency') }}</th>
                      <th>{{ $t('content.file') }}</th>
                      <th>{{ $t('content.defect') }}</th>
                      <th>{{ $t('content.detail') }}</th>
                      <th>{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>

                      <td class="text-sm font-weight-normal" colspan="9">
                        <material-input type="text" class="mb-0" :label="$t('content.serial')"
                          v-model="serial_searchP" />
                      </td>

                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                          @click="loadIllegalPlatformListApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in illegalPList" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPageP - 1) * perPageP + index + 1) }}
                      </td>

                      <td class="text-sm font-weight-normal">
                        {{ row.serialNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.reportBy }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.docNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ format_date(row.docDate) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.caseNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.source }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          v-if="row.refDocUid !== '0'" @click="getFileData(row)" :title="t('content.fileview')">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="modal"
                          data-bs-target="#attachmentsModal" v-show="false" id="attachmenDialog">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.flawMark }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.remark }}
                      </td>

                    </tr>
                    <tr v-if="illegalPList.length === 0">
                      <td colspan="10" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_sP }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="illegalPList.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPageP" :total-items="totalRowsP" :items-per-page="perPageP"
                      @click="onClickHandlerP" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>


        <div class="card mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.millegal') }} ({{ $t('apps.name') }})</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" data-bs-toggle="modal"
                    data-bs-target="#assetDataModal" id="assetModal" v-show="false" />
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.serial') }}</th>
                      <th>{{ $t('content.refer') }}</th>
                      <th>{{ $t('content.docno') }}</th>
                      <th>{{ $t('content.docdate') }}</th>
                      <th>{{ $t('content.criminal') }}</th>
                      <th>{{ $t('content.agency') }}</th>
                      <th>{{ $t('content.file') }}</th>
                      <th>{{ $t('content.defect') }}</th>
                      <th>{{ $t('content.detail') }}</th>
                      <th>{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>

                      <td class="text-sm font-weight-normal" colspan="9">
                        <material-input type="text" class="mb-0" :label="$t('content.serial')"
                          v-model="serial_search" />
                      </td>

                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                          @click="loadIllegalListApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in illegalList" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ (currentPage - 1) * perPage + index + 1 }}
                      </td>

                      <td class="text-sm font-weight-normal">
                        {{ row.serialNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.reportBy }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.docNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ format_date(row.docDate) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.caseNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.source }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          v-if="row.refDocUid !== '0'" @click="getFileData(row)" :title="t('content.fileview')">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="modal"
                          data-bs-target="#attachmentsModal" v-show="false" id="attachmenDialog">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.flawMark }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.remark }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                    </tr>
                    <tr v-if="illegalList.length === 0">
                      <td colspan="9" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="illegalList.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" style="max-width: 420px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Add/Eedit PlatformSanctionPerson Dialog -->
        <div id="assetDataModal" class="modal fade" tabindex="-1" aria-labelledby="assetModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.millegal") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">

                  <div class="col-lg-4">
                    <label class="form-label ms-0"> {{ $t("content.serial") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctiona_detail.serialNumber" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.refer") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctiona_detail.reportBy" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.docno") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctiona_detail.docNumber" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.docdate") }}</label>
                    <VueDatePicker v-model="sanctiona_detail.docDate" :format="format" auto-apply :teleport="true"
                      :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                      :max-date="new Date()" :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.criminal") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctiona_detail.caseNumber" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.agency") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctiona_detail.source" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.file") }}</label>
                    <div class="input-group custom-file-button">
                      <label for="upload" class="input-group-text mb-0">{{ $t("buttons.file") }}</label>
                      <input id="upload" type="file" accept="application/pdf,image/*" class="form-control mb-0"
                        @change="addFile" />
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <label class="form-label ms-0 mb-0 me-3">
                      {{ $t("content.defect") }}
                    </label>
                  </div>
                  <Textarea rows="2" v-model="sanctiona_detail.flawMark" class="w-100" />
                </div>


                <div class="col-12 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <label class="form-label ms-0 mb-0 me-3">
                      {{ $t("content.detail") }}
                    </label>
                  </div>
                  <Textarea rows="2" v-model="sanctiona_detail.remark" class="w-100" />
                </div>



              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ btnClose }}
                </button>
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" :disabled="sanctiona_detail.serialNumber === '' || sanctiona_detail.remark === ''
                  || sanctiona_detail.flawMark === ''" @click="checkprocess">
                  {{ btnSave }}
                </button>
              </div>



            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import Textarea from 'primevue/textarea';
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"



import * as st from "@/config/setting.js"
import axios from "axios";
import moment from 'moment'

import { useSanctionAssetsStore } from '@/stores/sanctionassets'
import { useDocumentStore } from '@/stores/document'

const storesanca = useSanctionAssetsStore()
const storedoc = useDocumentStore()

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  coreBox1: "",
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" }
  ],
  demo2: [
    { items: "สนง.ตำรวจแห่งชาติ" },
    { items: "สน.ท้องที่" },
    { items: "กรมการปกครอง" },
    { items: "ศาลอาญาฯ" },
    { items: "โรงรับจำนำในเครือ" },
  ],
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const thumb_name = ref("");
const thumb_id = ref("");
const thumb = ref(null);

const serial_search = ref("");
const serial_searchP = ref("");

const images = ref([])
const loading = ref(false);


const illegalList = ref([])
const illegalPList = ref([])


const sanctiona_detail = ref({
  uid: '',
  serialNumber: '',
  categoryUid: '0',
  docNumber: '',
  docDate: null,
  refDocUid: '0',
  reportDate: null,
  reportBy: '',
  remark: '',
  version: '',
  status: '',
  caseNumber: '',
  flawMark: '',
  source: ''
})

const key = JSON.parse(localStorage.getItem("shop_seleted"));


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}


const textInputOptions = {
  format: 'dd/MM/yyyy'
};


const onClickHandler = (page) => {
  updatePagination()
};

const onClickHandlerP = (page) => {
  updatePaginationP()
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageP = ref(1);
const totalRowsP = ref(1);
const perPageP = ref(10);
const totalRows_sP = ref();

const loadIllegalListApi = async (val) => {

  let data = "";
  if (currentPage.value !== 1) {
    if (illegalList.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  if (serial_search.value !== '' && val !== 'del') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    serialNumber: serial_search.value,


  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesanca.sanctionaListFetch(payload);
  // console.log("loadIllegalListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        illegalList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

loadIllegalListApi()



const loadIllegalPlatformListApi = async (val) => {

  let data = "";
  if (currentPageP.value !== 1) {
    if (illegalPList.value.length === 1) {
      currentPageP.value = currentPageP.value - 1
    }
  }
  if (serial_searchP.value !== '') {
    currentPageP.value = 1
  }
  data = {
    index: currentPageP.value - 1,
    size: perPageP.value,
    serialNumber: serial_searchP.value,


  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesanca.sanctionaPlatformListFetch(payload);
  // console.log("loadIllegalListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        illegalPList.value = response.data.data.list;
        totalRowsP.value = response.data.data.ext.total
        totalRows_sP.value = formatNumberString(totalRowsP.value)
      }
    }
  }
}

loadIllegalPlatformListApi()

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


async function addFile(e) {
  thumb.value = null
  thumb_name.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
    }
  }

}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});

function format_date(value) {
  if (value) {
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}


async function updatePagination() {
  illegalList.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    serialNumber: serial_search.value,


  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesanca.sanctionaListFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        illegalList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

async function updatePaginationP() {
  illegalPList.value = []
  let data = "";
  data = {
    index: currentPageP.value - 1,
    size: perPageP.value,
    serialNumber: serial_searchP.value,


  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesanca.sanctionaPlatformListFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        illegalPList.value = response.data.data.list;
        totalRowsP.value = response.data.data.ext.total
        totalRows_sP.value = formatNumberString(totalRows_sP.value)
      }
    }
  }
}

function clearItems() {
  thumb.value = null
  datas.addNew = false
  sanctiona_detail.value = {
    uid: '',
    serialNumber: '',
    categoryUid: '0',
    docNumber: '',
    docDate: null,
    refDocUid: '0',
    reportDate: null,
    reportBy: '',
    remark: '',
    version: '',
    status: '',
    caseNumber: '',
    flawMark: '',
    source: ''
  }
}

async function addSanctiona() {
  const payload = sanctiona_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }

  payload.reportDate = moment(new Date()).format('YYYY-MM-DD')
  payload.status = 'ACTIVE'

  if (payload.docDate !== null) {
    payload.docDate = moment(new Date(payload.docDate)).format('YYYY-MM-DD')
  } else {
    payload.docDate = null
  }

  // console.log('addSanctiona p : ' + JSON.stringify(payload));
  const response = await storesanca.sanctionaAddFetch(payload);
  // console.log('addSanctiona r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadIllegalListApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateSanctiona() {
  const payload = sanctiona_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }

  if (payload.reportDate !== null) {
    payload.reportDate = moment(new Date(payload.reportDate)).format('YYYY-MM-DD')
  }

  if (payload.docDate !== null) {
    payload.docDate = moment(new Date(payload.docDate)).format('YYYY-MM-DD')
  } else {
    payload.docDate = null
  }
  // console.log('updateSanctiona p : ' + JSON.stringify(payload));
  const response = await storesanca.sanctionaUpdateFetch(payload);
  // console.log('updateSanctiona r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadIllegalListApi('edit')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteSanctiona(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("docTypeDeleteFetch", payload);
  const response = await storesanca.sanctionaDeleteFetch(payload);
  // console.log("deletedocType : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadIllegalListApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addfileApi() {
  let data = "";
  data = {
    forGroupUid: key.groupUid,
    forShopUid: key.uid,
    name: thumb_name.value,
    data: thumb.value,
  };

  const payload = data;
  const response = await storedoc.addFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function getFileData(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.refDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    } else {
      loading.value = false
    }
  }
}



async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}


async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
      datas.addNew = true
      document.getElementById("upload").value = null;
      document.getElementById('assetModal').click()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addSanctiona()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItems()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
      sanctiona_detail.value = {
        uid: item.uid,
        serialNumber: item.serialNumber,
        categoryUid: item.categoryUid,
        docNumber: item.docNumber,
        docDate: item.docDate,
        refDocUid: item.refDocUid,
        reportDate: item.reportDate,
        reportBy: item.reportBy,
        remark: item.remark,
        version: item.version,
        status: item.status,
        caseNumber: item.caseNumber,
        flawMark: item.flawMark,
        source: item.source
      }
      if (sanctiona_detail.value.docDate !== null) {
        sanctiona_detail.value.docDate = new Date(sanctiona_detail.value.docDate)
      }
      if (sanctiona_detail.value.reportDate !== null) {
        sanctiona_detail.value.reportDate = new Date(sanctiona_detail.value.reportDate)
      }
      document.getElementById("upload").value = null;
      document.getElementById('assetModal').click()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItems()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      updateSanctiona()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItems()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
    }
  })
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteSanctiona(item.uid)
      }
    }
  });
}

function checkprocess() {
  if (datas.addNew) {
    addSanctiona()
  } else {
    updateSanctiona()
  }
}



</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>