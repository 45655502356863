<template>
    <div>

    </div>
</template>

<script>
export default {
    setup() {
        return {

        };
    },
}
</script>

<style lang="scss" scoped></style>