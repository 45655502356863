<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.ssetbranch') }}</h5>
                <!-- <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush mb-2">
                  <thead>
                    <tr>
                      <th>{{ $t('content.activity') }}</th>
                      <th width="100px">{{ $t('content.status') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="ps-1">
                        <div class="my-auto">
                          <span class="text-dark d-block text-lg">
                            {{ $t('content.setallopen') }}
                          </span>
                          <span class="text-xs font-weight-normal">
                            {{ $t('content.detailallopen') }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="ms-auto">
                          <material-switch id="setSystemOnOff" v-model="datas.switch1" name="setSystemOnOff"
                            class="form-check form-switch mb-0 align-items-center justify-content-center ms-1" />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="ps-1">
                        <div class="my-auto">
                          <span class="text-dark d-block text-lg">
                            {{ $t('content.setfixbackend') }}
                          </span>
                          <span class="text-xs font-weight-normal">
                            {{ $t('content.detailfixbackend') }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="ms-auto">
                          <material-switch id="setBackendOnOff" v-model="datas.switch2" name="setBackendOnOff"
                            class="form-check form-switch mb-0 align-items-center justify-content-center ms-1"
                            :disabled="datas.switch1 ? false : true" />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="p-0">
                        <div v-if="datas.switch2" class="table-responsive">
                          <table class="table table-flush">
                            <thead class="thead-light">
                              <tr class="sub-header">
                                <th>{{ $t('content.day') }}</th>
                                <th width="100px">{{ $t('content.status') }}</th>
                                <th width="140px">{{ $t('content.opentime') }}</th>
                                <th width="40px">{{ $t('content.to') }}</th>
                                <th width="140px">{{ $t('content.closetime') }}</th>
                                <th width="120px">{{ $t('content.act') }}</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.sun') }} <span class="text-info">(
                                    {{
                                      datas.switchd1 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="sunday" v-model="datas.switchd1" name="sunday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">null</td>
                                <td class="text-sm font-weight-normal">-</td>
                                <td class="text-sm font-weight-normal">null</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                    @click="editRowItems">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr v-if="datas.rowEdit">
                                <td class="text-sm font-weight-normal">{{ $t('content.mon') }} <span class="text-info">(
                                    {{
                                      datas.switchd2 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="monday" v-model="datas.switchd2" name="monday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">
                                  <material-input type="time" class="mb-0" :disabled="!datas.switchd2 ? true : false" />
                                </td>
                                <td class="text-sm font-weight-normal">-</td>
                                <td class="text-sm font-weight-normal">
                                  <material-input type="time" class="mb-0" :disabled="!datas.switchd2 ? true : false" />
                                </td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="primary" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                    @click="saveEditRowItems">
                                    <i class="material-icons material-symbols-outlined">save</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                                    @click="cancelEditRowItems">
                                    <i class="material-icons material-symbols-outlined">close</i>
                                  </material-button>
                                </td>
                              </tr>
                              <tr v-else-if="!datas.rowEdit">
                                <td class="text-sm font-weight-normal">{{ $t('content.mon') }} <span class="text-info">(
                                    {{
                                      datas.switchd2 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="monday2" v-model="datas.switchd2" name="monday2"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal">-</td>
                                <td class="text-sm font-weight-normal">18:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                    @click="editRowItems">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.tue') }} <span class="text-info">(
                                    {{
                                      datas.switchd3 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="tuesday" v-model="datas.switchd3" name="tuesday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal"></td>
                                <td class="text-sm font-weight-normal">18:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.wed') }} <span class="text-info">(
                                    {{
                                      datas.switchd4 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="wednesday" v-model="datas.switchd4" name="wednesday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal"></td>
                                <td class="text-sm font-weight-normal">18:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.thu') }} <span class="text-info">(
                                    {{
                                      datas.switchd5 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="thursday" v-model="datas.switchd5" name="thursday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal"></td>
                                <td class="text-sm font-weight-normal">18:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.fri') }} <span class="text-info">(
                                    {{
                                      datas.switchd6 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="friday" v-model="datas.switchd6" name="friday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal"></td>
                                <td class="text-sm font-weight-normal">18:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                              <tr>
                                <td class="text-sm font-weight-normal">{{ $t('content.sat') }} <span class="text-info">(
                                    {{
                                      datas.switchd7 ? $t('content.open') : $t('content.close') }} )</span> </td>
                                <td class="text-sm font-weight-normal">
                                  <material-switch id="saturday" v-model="datas.switchd7" name="saturday"
                                    class="form-check form-switch mb-0 align-items-center justify-content-center" />
                                </td>
                                <td class="text-sm font-weight-normal">08:00</td>
                                <td class="text-sm font-weight-normal"></td>
                                <td class="text-sm font-weight-normal">12:00</td>
                                <td class="text-sm font-weight-normal">
                                  <material-button color="info" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                                    <i class="material-icons material-symbols-outlined">edit</i>
                                  </material-button>
                                  <material-button color="danger" variant="outline" class="rounded-circle"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                    <i class="material-icons material-symbols-outlined">delete</i>
                                  </material-button>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="ps-1">
                        <div class="my-auto">
                          <span class="text-dark d-block text-lg">
                            {{ $t('content.amnfixticket') }}
                          </span>
                          <div class="mt-2">
                            <div class="form-check">
                              <input v-model="shop_detail.wfType" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1" value="1">
                              <label class="form-check-label" for="flexRadioDefault1">
                                {{ $t('content.amnfixtogroup') }}
                              </label>
                            </div>
                            <div class="form-check">
                              <input v-model="shop_detail.wfType" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2" value="2">
                              <label class="form-check-label" for="flexRadioDefault2">
                                {{ $t('content.amnfixtobiz') }}
                              </label>
                            </div>
                            <!-- <div class="form-check">
                              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                              <label class="form-check-label" for="flexRadioDefault3">
                                {{ $t('content.amnfixtonone') }}
                              </label>
                            </div> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td colspan="2" class="ps-1">
                        <div class="row no-gutters">
                          <div class="col-12 d-flex justify-content-between align-items-center">
                            <h5></h5>
                            <div>
                              <material-button color="primary" class="float-lg-start float-sm-end mb-0" :title="btnSave"
                                @click="updateshopdata">
                                <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                              </material-button>
                            </div>
                          </div>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialSwitch from '@/components/MaterialSwitch.vue'

import { useShopStore } from '@/stores/shop'

const stores = useShopStore()
// import MaterialRadio from "@/components/MaterialRadio.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"

// import logo from "@/assets/img/pawn/logo_buddy.png"

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  switch1: false,
  switch2: false,
  switchd1: false,
  switchd2: false,
  switchd3: false,
  switchd4: false,
  switchd5: false,
  switchd6: false,
  switchd7: false,
  rowEdit: false,
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" }
  ]
})

const btnEdit = computed(() => t("buttons.edit"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const shop_detail = ref({})
const key = JSON.parse(localStorage.getItem("shop_seleted"));


const loadDataShop = async () => {
  if (localStorage.getItem("shop_seleted") !== null) {
    // shop_detail.value = JSON.parse(localStorage.getItem("shop_seleted"));
    await getshopInfo()
  }
};

loadDataShop();

async function getshopInfo() {

  let data = "";
  data = {
    uid: key.uid,
  };

  const payload = data;
  const response = await stores.shopInfoFetch(payload);
  // console.log('getshopInfo : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // swal("บันทึกสำเร็จ", "", "success");
        // localStorage.setItem("shop_seleted", JSON.stringify(response.data.data));
        shop_detail.value = response.data.data;
        console.log('shop_detail : ' + JSON.stringify(shop_detail.value));
      } else {
        // swal("บันทึกไม่สำเร็จ", "", "error");
      }
    }
  }
}


async function updateshopdata() {

  const payload = shop_detail.value;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await stores.shopUpdateFetch(payload);
  // console.log('editItems : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        getshopInfo()
        // localStorage.setItem("shop_seleted", JSON.stringify(response.data.data));
        // shop_detail.value = JSON.parse(localStorage.getItem("shop_seleted"));
      } else {
        swal("บันทึกไม่สำเร็จ", "", "error");
      }
    }
  }
}

// async function addNewItems() {
//   swal({
//     title: "เพิ่มรายการ",
//     text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
//     icon: "info",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.conf'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       datas.addNew = true
//     } else if (result.dismiss == 'cancel') {
//       swal("ยกเลิกรายการ", "", "error")
//       datas.addNew = false
//     }
//   })
// }

// async function saveNewItems() {
//   swal({
//     title: "ต้องการบันทึกรายการนี้",
//     text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
//     icon: "info",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.save'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       swal("บันทึกสำเร็จ", "", "success")
//       datas.addNew = false
//     } else if (result.dismiss == 'cancel') {
//       swal("ยกเลิกการบันทึก", "", "error")
//       datas.addNew = false
//     }
//   })
// }

// async function cancelSaveNewItems() {
//   swal({
//     title: "ยกเลิกบันทึกรายการนี้",
//     text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
//     icon: "warning",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.conf'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       datas.addNew = false
//     } else if (result.dismiss == 'cancel') {
//       datas.addNew = true
//     }
//   })
// }

async function editRowItems() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowEdit = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.rowEdit = false
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      datas.rowEdit = false
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      datas.rowEdit = false
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowEdit = false
    } else if (result.dismiss == 'cancel') {
      datas.rowEdit = true
    }
  })
}

</script>

<style lang="scss" scoped></style>