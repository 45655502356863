<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.mcash') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 70px;">#</th>
                      <th>{{ $t('content.mcashname') }}</th>
                      <th>{{ $t('content.amnmoneylimit') }}</th>
                      <th style="width: 140px;">{{ $t('content.mcolor') }}</th>
                      <th colspan="4" class="align-top text-center">{{ $t('content.amnchannel') }}</th>
                      <th style="width: 100px;">{{ $t('content.act') }}</th>
                    </tr>
                    <tr>
                      <th colspan="4"></th>
                      <th class="align-top text-center">
                        {{ $t("content.fo_mobile") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.fo_web") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.bo_mobile") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.bo_web") }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="7">
                        <material-input v-model="filterText" type="text" class="mb-0"
                          :label="$t('content.mcashname')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newbank" type="text" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <InputNumber v-model="newlimit" :minFractionDigits="0" :maxFractionDigits="2" class="mb-0"
                          autocomplete="off" @focus="$event.target.select()" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newcolor" type="color" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_MOBILE" :checked="FO_MOBILE" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_WEB" :checked="FO_WEB" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_MOBILE" :checked="BO_MOBILE" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_WEB" :checked="BO_WEB" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newbank.trim() === '' || newlimit === '' || newlimit === null || newlimit === '0' || parseFloat(newlimit) === 0 || newcolor === ''"
                          @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in list" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newbank" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <InputNumber v-model="newlimit" :minFractionDigits="0" :maxFractionDigits="2" class="mb-0"
                          autocomplete="off" @focus="$event.target.select()" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newcolor" type="color" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_MOBILE" :checked="FO_MOBILE" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_WEB" :checked="FO_WEB" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_MOBILE" :checked="BO_MOBILE" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_WEB" :checked="BO_WEB" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newbank.trim() === '' || newlimit === '' || newcolor === ''"
                          @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.name }}
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.maxLimit_s }}
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <material-input v-model="row.color" type="color" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.FO_MOBILE" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.FO_WEB" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.BO_MOBILE" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.BO_WEB" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                    </tr>
                    <tr v-if="list.length === 0">
                      <td></td>

                      <td colspan="10" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="list.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      :on-click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import InputNumber from 'primevue/inputnumber';
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import moment from 'moment'
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";

import { useCashAccountStore } from '@/stores/cashaccount'
import { useBankNoteStore } from '@/stores/banknote'
import { useCloseCashStore } from '@/stores/closecash'


const storec = useCashAccountStore()
const storeb = useBankNoteStore()
const storecc = useCloseCashStore()

const { t } = useI18n()
const swal = inject('$swal')


const datas = reactive({
  addNew: false,
  rowEdit: false,
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" }
  ]
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
// const btnCancel = computed(() => t("buttons.canc"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const filterText = ref("");
const list = ref([]);
const newbank = ref("");
const newlimit = ref("");
const newcolor = ref("");
const addNew = ref(false);
const rowEdit = ref(false);
const uid = ref("");
const version = ref("");
const cashDetails = ref([]);

// const lbShort = computed(() => t("content.mbankpshort"))
// const lbColor = computed(() => t("content.mcolor"))
// const lbNote = computed(() => t("content.note"))
// const lbBankLogo = computed(() => t("content.mlogo"))

const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const channels = ref([]);
const FO_MOBILE = ref(false);
const FO_WEB = ref(false);
const BO_MOBILE = ref(false);
const BO_WEB = ref(false);

const key = JSON.parse(localStorage.getItem("shop_seleted"));

const loadDataApi = async (val) => {
  clearItems();

  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    shopUid: key.uid

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;

        listd.map(function (x) {
          x.maxLimit_s = formatNumberString(x.maxLimit);
          x.FO_MOBILE = false
          x.FO_WEB = false
          x.BO_MOBILE = false
          x.BO_WEB = false
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('FO_MOBILE')) {
            listd[i].FO_MOBILE = true
          }
          if (listd[i].channels.includes('FO_WEB')) {
            listd[i].FO_WEB = true
          }
          if (listd[i].channels.includes('BO_MOBILE')) {
            listd[i].BO_MOBILE = true
          }
          if (listd[i].channels.includes('BO_WEB')) {
            listd[i].BO_WEB = true
          }
        }

        list.value = listd;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }
};

loadDataApi();

async function updatePagination() {

  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    shopUid: key.uid

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  // const response = await store.dispatch("banknoteListFetch", payload);
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;

        listd.map(function (x) {
          x.maxLimit_s = formatNumberString(x.maxLimit);
          x.FO_MOBILE = false
          x.FO_WEB = false
          x.BO_MOBILE = false
          x.BO_WEB = false
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('FO_MOBILE')) {
            listd[i].FO_MOBILE = true
          }
          if (listd[i].channels.includes('FO_WEB')) {
            listd[i].FO_WEB = true
          }
          if (listd[i].channels.includes('BO_MOBILE')) {
            listd[i].BO_MOBILE = true
          }
          if (listd[i].channels.includes('BO_WEB')) {
            listd[i].BO_WEB = true
          }
        }


        list.value = listd;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }

}


const loadBanknote = async (val) => {
  cashDetails.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: '',
    multiply: '',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  // const response = await store.dispatch("banknoteListFetch", payload);
  const response = await storeb.banknoteListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        listd.map(function (x) {
          x.billCount = '0'
          return x
        });
        cashDetails.value = listd;
        // console.log("loadBanknote : " + JSON.stringify(listbanknote.value));
      }
    }
  }
};

loadBanknote()




function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function clearItems() {
  addNew.value = false;
  rowEdit.value = false;
  uid.value = "";
  newbank.value = "";
  newlimit.value = "";
  newcolor.value = "";
  version.value = "";
  FO_MOBILE.value = false
  FO_WEB.value = false
  BO_MOBILE.value = false
  BO_WEB.value = false
  channels.value = []
}



async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      addNew.value = true;
      rowEdit.value = false;
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      addNew.value = false;
      clearItems();
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      addcashacc()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      addNew.value = false;
      clearItems();
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      clearItems();
    } else if (result.dismiss == 'cancel') {
      addNew.value = true;
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      rowEdit.value = true;
      uid.value = item.uid;
      newbank.value = item.name;
      newlimit.value = item.maxLimit;
      newcolor.value = item.color;
      version.value = item.version;
      FO_MOBILE.value = item.FO_MOBILE
      FO_WEB.value = item.FO_WEB
      BO_MOBILE.value = item.BO_MOBILE
      BO_WEB.value = item.BO_WEB
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      rowEdit.value = false;
      clearItems();
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEdit.value = false;
      if (uid.value !== "" && version.value !== "") {
        updatecashacc()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      rowEdit.value = false;
      clearItems();
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
    } else if (result.dismiss == 'cancel') {
      rowEdit.value = true;
    }
  })
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletecashacc(item.uid);
      }
    }
  });
}

async function addcashacc() {
  channels.value = []
  await findChannels()
  let data = "";
  data = {
    shopUid: key.uid,
    name: newbank.value,
    color: newcolor.value,
    maxLimit: parseFloat(newlimit.value).toFixed(2),
    channels: channels.value
  };
  const payload = data;
  // const response = await store.dispatch("banknoteAddFetch", payload);
  const response = await storec.cashaccountAddFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        addCloseCashOpenshop(response.data.data)
        loadDataApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatecashacc() {
  channels.value = []
  await findChannels()
  let data = "";
  data = {
    uid: uid.value,
    shopUid: key.uid,
    name: newbank.value,
    color: newcolor.value,
    maxLimit: parseFloat(newlimit.value).toFixed(2),
    version: version.value,
    channels: channels.value
  };
  const payload = data;
  // console.log('updatebanknote 1 : ' + JSON.stringify(payload));
  // const response = await store.dispatch("banknoteUpdateFetch", payload);
  const response = await storec.cashaccountUpdateFetch(payload);
  // console.log('updatebanknote 2 : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletecashacc(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("banknoteDeleteFetch", payload);
  const response = await storec.cashaccountDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addCloseCashOpenshop(item) {
  let payload = ""
  payload = {
    cashAccUid: item.uid,
    cashDetails: cashDetails.value,
    closingDateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
  }
  // console.log('addCloseCash p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashNewOpenshopFetch(payload);
  // console.log('addCloseCash r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function findChannels() {
  let allow = []
  if (FO_MOBILE.value) {
    allow.push('FO_MOBILE')
  }
  if (FO_WEB.value) {
    allow.push('FO_WEB')
  }
  if (BO_MOBILE.value) {
    allow.push('BO_MOBILE')
  }
  if (BO_WEB.value) {
    allow.push('BO_WEB')
  }

  channels.value = allow
}



</script>

<style lang="scss" scoped></style>