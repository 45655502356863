<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.mholiday') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 70px;">#</th>
                      <th style="width: 200px;">{{ $t('content.date') }}</th>
                      <th>{{ $t('content.detail') }}</th>
                      <th style="width: 100px;">{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="2">
                        <!-- <VueDatePicker v-model="holiday_search" :format="format" auto-apply :teleport="true"
                          :enable-time-picker="false" month-name-format="long" :clearable="true" fluid
                          :text-input="textInputOptions" /> -->
                        <select class="form-select" v-model="holiday_search">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(item, index) in listyear" :value="item.items" :key="index">
                            {{ item.items }}
                          </option>
                        </select>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="datas.addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <VueDatePicker v-model="holiday_detail.holidayDate" :format="format" auto-apply :teleport="true"
                          :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                          :text-input="textInputOptions" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="holiday_detail.description" type="text" class="mb-0" value="" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="holiday_detail.holidayDate === null || holiday_detail.description === ''"
                          @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in listholiday" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid !== holiday_detail.uid" class="text-sm font-weight-normal">
                        {{ format_date(row.holidayDate) }}
                      </td>
                      <td v-if="row.uid !== holiday_detail.uid" class="text-sm font-weight-normal">
                        {{ row.description }}
                      </td>
                      <td v-if="row.uid !== holiday_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid === holiday_detail.uid" class="text-sm font-weight-normal">
                        <VueDatePicker v-model="holiday_detail.holidayDate" :format="format" auto-apply :teleport="true"
                          :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                          :text-input="textInputOptions" />
                      </td>
                      <td v-if="row.uid === holiday_detail.uid" class="text-sm font-weight-normal">
                        <material-input v-model="holiday_detail.description" type="text" class="mb-0" value="" />
                      </td>
                      <td v-if="row.uid === holiday_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="holiday_detail.holidayDate === null || holiday_detail.description === ''"
                          @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>
                    <tr v-if="listholiday.length === 0">
                      <td colspan="5" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="8" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="listholiday.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, reactive, inject, computed } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import ExampleFive from "@/examples/ExampleOne";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'

import { useShopHolidayStore } from '@/stores/shopholiday'

const storeh = useShopHolidayStore()


const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const formatyear = (date) => {
  return moment(String(date)).local().format('YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const holiday_search = ref(formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 0))));
const listholiday = ref([]);

const holiday_detail = ref({
  uid: '',
  shopUid: '',
  holidayDate: new Date(),
  description: '',
  version: '',
})

const listyear = ref([
  { items: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 1))) },
  { items: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 0))) },
  { items: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() + 1))) },
]);

const key = JSON.parse(localStorage.getItem("shop_seleted"));

const onClickHandler = (page) => {
  updatePagination()
};


const loadDataApi = async (val) => {
  let sd = "";
  let data = "";
  if (currentPage.value !== 1) {
    if (listholiday.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  if (holiday_search.value !== null && val !== 'del') {
    currentPage.value = 1
    // sd = moment(new Date(holiday_search.value)).local().format('YYYY-MM-DD')
    sd = holiday_search.value
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    holidayDate: sd,
    shopUid: key.uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storeh.shopHolidayListFetch(payload);
  console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listholiday.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

loadDataApi()

async function updatePagination() {
  listholiday.value = []
  let data = "";
  let sd = "";
  if (holiday_search.value !== null) {
    // sd = moment(new Date(holiday_search.value)).local().format('YYYY-MM-DD')
    sd = holiday_search.value
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    holidayDate: sd,
    shopUid: key.uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storeh.shopHolidayListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listholiday.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function clearItem() {
  datas.addNew = false
  datas.rowEdit = false
  holiday_detail.value = {
    uid: '',
    shopUid: '',
    holidayDate: new Date(),
    description: '',
    version: '',
  }
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItem()
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addHoliday()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItem()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      holiday_detail.value = {
        uid: item.uid,
        shopUid: item.shopUid,
        holidayDate: new Date(item.holidayDate),
        description: item.description,
        version: item.version,
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItem()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      updateHoliday()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItem()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
    }
  })
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteHoliday(item.uid)
      }
    }
  });
}

async function addHoliday() {
  const payload = holiday_detail.value;
  payload.holidayDate = moment(new Date(payload.holidayDate)).local().format('YYYY-MM-DD')
  payload.shopUid = key.uid
  const response = await storeh.shopHolidayAddFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateHoliday() {
  const payload = holiday_detail.value;
  payload.holidayDate = moment(new Date(payload.holidayDate)).local().format('YYYY-MM-DD')
  payload.shopUid = key.uid
  const response = await storeh.shopHolidayUpdateFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deleteHoliday(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storeh.shopHolidayDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}




</script>

<style lang="scss" scoped></style>