<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="userSettingTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-userSetting" class="nav-link active" data-bs-toggle="tab" data-bs-target="#firstUserSetting"
              type="button" role="tab" aria-controls="firstUserSetting" aria-selected="true">
              {{ $t('content.amnuser') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-userSetting" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondUserSetting"
              type="button" role="tab" aria-controls="secondUserSetting" aria-selected="false">
              {{ $t('content.amnusermanage') }}
            </button>
          </li>
        </ul>

        <div id="userSettingTabContent" class="tab-content">

          <div id="firstUserSetting" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-userSetting">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amnuser') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnusername') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName"
                        readonly>
                      <button id="searchName" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnusernick') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}</label>
                    <material-input type="tel" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.amnusername') }}</th>
                          <th>{{ $t('content.names') }}</th>
                          <th>{{ $t('content.amnusernick') }}</th>
                          <th>{{ $t('content.cmobile') }}</th>
                          <th width="100px">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, ci) in samples" :key="ci">
                          <td class="text-sm font-weight-normal">{{ i.no }}</td>
                          <td class="text-sm font-weight-normal">{{ i.usr }}</td>
                          <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                          <td class="text-sm font-weight-normal">{{ i.nic }}</td>
                          <td class="text-sm font-weight-normal">{{ i.mob }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnReset">
                              <i class="material-icons material-symbols-outlined">lock_reset</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondUserSetting" class="tab-pane fade" role="tabpanel" aria-labelledby="second-userSetting">
            <div class="card mt-2">
              <div class="card-header">
                <h5>{{ $t('content.amnusermanage') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.amnusername') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png">{{ selectAdmin }}</label>
                    <material-checkbox id="userAdmin" v-model="selectAdmin" class="checkbox-horizon pt-2" name="selAdmin">
                      {{ $t('content.amnadmin') }}
                    </material-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cprefix') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs2) in datas.demo4" :key="fs2" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.cnam') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.clsnam') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amneffectdate') }}</label>
                    <material-input type="date" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnenddate') }}</label>
                    <material-input type="date" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amnuserright') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, us1) in datas.demoRole" :key="us1" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12">
                    <label class="form-label ms-0">{{ $t('content.caddress') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cstate') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">กรุงเทพฯ</a></li>
                        <li><a class="dropdown-item" href="#">นนทบุรี</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.csprov') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">พระนคร</a></li>
                        <li><a class="dropdown-item" href="#">ป้อมปราบฯ</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cscity') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">บวรนิเวศ</a></li>
                        <li><a class="dropdown-item" href="#">ตลาดยอด</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}1*</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}2</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}3</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-12">
                    <label class="form-label ms-0">{{ $t('content.cemail') }}</label>
                    <material-input type="email" />
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <material-button color="default" class="float-lg-start float-sm-end mb-0 me-2" @click="clearNewItems">
                      <i class="material-icons material-symbols-outlined">delete</i> {{ btnClear }}
                    </material-button>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="saveNewItems">
                      <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                    </material-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                  <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                    <i class="material-icons material-symbols-outlined">search</i>
                                  </material-button>
                                </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
// import MaterialRadio from "@/components/MaterialRadio.vue"

// import Signature from "@/assets/img/pawn/signature_test.png"
// import FingerPrint from "@/assets/img/pawn/fingerprint_test.jpg"
// import Avatar from "@/assets/img/ivana-squares.jpg"

const { t } = useI18n()
const swal = inject('$swal')

const selectAdmin = ref(false)

const datas = reactive({
  check1: false,
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  searchBox1: "",
  searchBox2: "",
  searchBox3: "",
  addRef: false,
  rowRef: false,
  addSubRef: false,
  demo1: [
    { items: "ปกติ" },
    { items: "ล็อค" },
  ],
  demo2: [
    { items: "ธรรมดา" },
    { items: "VIP 1" },
    { items: "VIP 2" },
    { items: "VIP 3" },
    { items: "VIP 4" },
  ],
  demo3: [
    { items: "บัตรประชาชน" },
    { items: "ใบอนุญาตทำงาน" },
    { items: "บัตรต่างด้าว" },
    { items: "หนังสือเดินทาง" },
    { items: "ใบขับขี่" },
  ],
  demo4: [
    { items: "นาย" },
    { items: "นาง" },
    { items: "นางสาว" },
  ],
  demo5: [
    { items: "พุทธ" },
    { items: "คริสต์" },
    { items: "อิสลาม" },
    { items: "ฮินดู" },
    { items: "ซิกต์" },
    { items: "ไม่มี" },
  ],
  demo6: [
    { items: "A" },
    { items: "B" },
    { items: "O" },
    { items: "AB" },
  ],
  demo7: [
    { items: "ปกติ" },
    { items: "ค่าเริ่มต้น" },
    { items: "หยุดใช้งาน" },
    { items: "ผิดปกติ" },
  ],
  demo8: [
    { items: "กสิกรไทย" },
    { items: "ไทยพาณิชย์" },
    { items: "กรุงไทย" },
    { items: "กรุงเทพ" },
  ],
  demo9: [
    { items: "1.75%" },
    { items: "1.5%" },
    { items: "1%" },
    { items: "0.75%" },
  ],
  demo10: [
    { items: "ประเภทเอกสาร 1" },
    { items: "ประเภทเอกสาร 2" },
    { items: "ประเภทเอกสาร 3" },
    { items: "ประเภทเอกสาร 4" },
  ],
  demoRole: [
    { items: "สิทธิ์ 1" },
    { items: "สิทธิ์ 2" },
    { items: "สิทธิ์ 3" },
    { items: "สิทธิ์ 4" },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

const samples = reactive([
  {
    no: "1",
    usr: "user-a",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    css: datas.demo1[0].items,
    grd: datas.demo2[1].items,
    crd: "x-xxxx-xxxxx-xx-x",
    nic: "nickname",
    mob: "8888888888",
  },
  {
    no: "2",
    usr: "user-b",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-1132",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    css: datas.demo1[0].items,
    grd: datas.demo2[4].items,
    crd: "x-xxxx-xxxxx-xx-x",
    nic: "nickname",
    mob: "8888888888",
  },
  {
    no: "3",
    usr: "user-c",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-5611",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    css: datas.demo1[0].items,
    grd: datas.demo2[2].items,
    crd: "x-xxxx-xxxxx-xx-x",
    nic: "nickname",
    mob: "8888888888",
  },
  {
    no: "4",
    usr: "user-d",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-0021",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    css: datas.demo1[1].items,
    grd: datas.demo2[0].items,
    crd: "x-xxxx-xxxxx-xx-x",
    nic: "nickname",
    mob: "8888888888",
  },
])

// const btnAddNew = computed(() => t("buttons.impo"))
// const btnEdit = computed(() => t("buttons.edit"))
// const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnClear = computed(() => t("buttons.clea"))
// const btnDel = computed(() => t("buttons.remo"))
// const btnClose = computed(() => t("buttons.clos"))
// const btnConfirm = computed(() => t("buttons.conf"))
// const btnCancel = computed(() => t("buttons.canc"))
// const btnPreview = computed(() => t("content.file"))
// const btnReset = computed(() => t("content.resetpwd"))

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
    }
  })
}

async function clearNewItems() {
  swal({
    title: "ล้างแบบฟอร์มข้อมูล",
    text: "คุณต้องการล้างแบบฟอร์มข้อมูลนี้ใช่ไหม",
    icon: "danger",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("ล้างแบบฟอร์มข้อมูลสำเร็จ", "", "success")
    } else if (result.dismiss == 'cancel') {
      swal("กลับไปยังแบบฟอร์มข้อมูล", "", "info")
    }
  })
}

</script>
    
<style lang="scss" scoped></style>