/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCategoryStore = defineStore("category", {
  actions: {
    async categoryfullNameFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/list/full/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categorylistPrintFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/list/print";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "parent.uid": payload.uid, // empty,"null" (for top level category), parent uid
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryAddFetch(payload) {
      const body = {
        name: payload.name,
        usable: payload.usable,
        enable: payload.enable,
        parentUid: payload.parentUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        // level: payload.level,
        name: payload.name,
        usable: payload.usable,
        parentUid: payload.parentUid,
        version: payload.version,
        enable: payload.enable,
      };
      // console.log("categoryUpdateFetch : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryUpdatePrintFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        l2Print: payload.l2Print,
        l3Print: payload.l3Print,
        l4Print: payload.l4Print,
        l5Print: payload.l5Print,
        l6Print: payload.l6Print,
        reqWeight: payload.reqWeight,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/update/print";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async categoryDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
