import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import i18n from "./i18n";
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
import "vue-awesome-paginate/dist/style.css";
import VueAwesomePaginate from "vue-awesome-paginate";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const pinia = createPinia();

const options = {
  confirmButtonColor: "#05af50",
  cancelButtonColor: "#f44335",
};

const appInstance = createApp(App);
appInstance.use(i18n);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2, options);
appInstance.use(VueApexCharts);
appInstance.use(moment);
appInstance.use(MaterialDashboard);
appInstance.use(VueAwesomePaginate);
appInstance.use(pinia);
appInstance.use(PrimeVue, {
  theme: {
    preset: Aura,
  },
});
appInstance.use(VueDatePicker);
appInstance.mount("#app");
