/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCustomerStore = defineStore("customer", {
  actions: {
    async customerInfoByDocNumberFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/customer/info/doc-number/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async customerInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/customer/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async searchCustomerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          docNumber: payload.docNumber,
          "nameEn:contains": payload.nameEn,
          "nameTh:contains": payload.nameTh,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/customer/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async newCustomerFetch(payload) {
      const body = {
        uid: payload.uid,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        birthOrRegDate: payload.birthOrRegDate,
        address: payload.address,
        profileImgUid: payload.profileImgUid,
        docTypeUid: payload.docTypeUid,
        docNumber: payload.docNumber,
        issuedBy: payload.issuedBy,
        issuedDate: payload.issuedDate,
        expiredDate: payload.expiredDate,
        version: payload.version,
        groupTicket: payload.groupTicket,
        failGrpTicket: payload.failGrpTicket,
        activeGrpTicket: payload.activeGrpTicket,
        lifetime: payload.lifetime,
        // cusShop: {
        //   cusUid: payload.cusUid,
        // shopUid: payload.shopUid,
        //   cusTypeUid: payload.cusTypeUid,
        //   interestUid: payload.interestUid,
        //   openAccountDate: payload.openAccountDate,
        //   lastActiveDate: payload.lastActiveDate,
        //   phoneNo1: payload.phoneNo1,
        //   phoneNo2: payload.phoneNo2,
        //   phoneNo3: payload.phoneNo3,
        //   signature1Uid: "0",
        //   signature2Uid: "0",
        //   fingerPrint1Uid: "0",
        //   fingerPrint2Uid: "0",
        //   email: payload.email,
        //   warningMsg: payload.warningMsg,
        //   notifyWarning: payload.notifyWarning,
        // },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/customer/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async updateCustomerFetch(payload) {
      const body = {
        uid: payload.uid,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        birthOrRegDate: payload.birthOrRegDate,
        address: payload.address,
        profileImgUid: payload.profileImgUid,
        docTypeUid: payload.docTypeUid,
        docNumber: payload.docNumber,
        issuedBy: payload.issuedBy,
        issuedDate: payload.issuedDate,
        expiredDate: payload.expiredDate,
        version: payload.version,
        groupTicket: payload.groupTicket,
        failGrpTicket: payload.failGrpTicket,
        activeGrpTicket: payload.activeGrpTicket,
        lifetime: payload.lifetime,
        // cusShop: {
        //   cusUid: payload.cusUid,
        // shopUid: payload.shopUid,
        //   cusTypeUid: payload.cusTypeUid,
        //   interestUid: payload.interestUid,
        //   openAccountDate: payload.openAccountDate,
        //   lastActiveDate: payload.lastActiveDate,
        //   phoneNo1: payload.phoneNo1,
        //   phoneNo2: payload.phoneNo2,
        //   phoneNo3: payload.phoneNo3,
        //   signature1Uid: "0",
        //   signature2Uid: "0",
        //   fingerPrint1Uid: "0",
        //   fingerPrint2Uid: "0",
        //   email: payload.email,
        //   warningMsg: payload.warningMsg,
        //   notifyWarning: payload.notifyWarning,
        //   version: payload.version,
        // },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/customer/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
