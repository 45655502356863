<template>
  <div class="input-group" :class="`input-group-${variant} ${getStatus(error, success)}`">
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input :id="id" :type="type" class="form-control" :class="getClasses(size)" :name="name" :value="modelValue"
      :placeholder="placeholder" :isRequired="isRequired" :disabled="disabled" :maxlength="maxlength" @input="onChecked"
      autocomplete="off" />
  </div>
</template>

<script setup>
import setMaterialInput from "@/assets/js/material-input.js";
import { defineProps, toRefs, defineEmits, onMounted } from "vue"

const props = defineProps({
  variant: {
    type: String,
    default: "outline",
  },
  label: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: "",
    required: true,
  },
  maxlength: {
    type: String,
    default: "",
  },
})

const { name, id, variant, label, size, disabled, success, error, placeholder, type, isRequired, maxlength } = toRefs(props)

const getClasses = (size) => {
  let sizeValue;
  sizeValue = size ? `form-control-${size}` : null;

  return sizeValue;
}

const getStatus = (error, success) => {
  let isValidValue;

  if (success) {
    isValidValue = "is-valid";
  } else if (error) {
    isValidValue = "is-invalid";
  } else {
    isValidValue = null;
  }

  return isValidValue;
}

const emit = defineEmits(['update:modelValue'])
const onChecked = (event) => emit('update:modelValue', event.target.value)

onMounted(() => {
  setMaterialInput();
});


</script>
