<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <div class="card mt-2">
          <div class="card-header mb-0">
            <h5>{{ $t("content.amnlocation") }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12">
                <Tree v-model:expandedKeys="expandedKeys" :value="item" :metaKeySelection="false" selectionMode="single"
                  class="w-full md:w-30rem" @nodeSelect="onItemSelected" @nodeCollapse="onCollapsed">
                  <template #default="slotProps">
                    <b v-show="false">{{ slotProps.node.name }}</b>
                    <material-tree-view :model="slotProps.node" class="item" @add="addClick" @edit="editClick"
                      @change="delClick" />
                  </template>
                </Tree>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
/* eslint-disable no-unused-vars */
import { ref, inject } from "vue";
import MaterialTreeView from "@/components/TreeView.vue";
import { useStore } from "vuex";
import Tree from 'primevue/tree';
import { useLocationStore } from '@/stores/location'


const swal = inject('$swal')

const store = useStore();
const storel = useLocationStore()

// const { t } = useI18n();
// const swal = inject("$swal");
// demo data
const treeData = ref({
  name: "Level 1",
  children: [
    {
      name: "Level 2",
      children: [
        {
          name: "Level 3",
          children: [
            {
              name: "Level 4",
              children: [
                {
                  name: "Level 5",
                  children: [
                    {
                      name: "Level 6",
                    },
                  ],
                },
              ],
            },
          ],
        },
        { name: "Level 3" },
        { name: "Level 3" },
        {
          name: "Level 3",
          children: [{ name: "Level 4" }, { name: "Level 4" }],
        },
      ],
    },
  ],
});

const item = ref([]);
const grandList = ref([]);
const childList = ref([]);
const expand = ref([]);
const selected = ref([]);
const loading = ref(false);
const expandedKeys = ref({});
const key = JSON.parse(localStorage.getItem("shop_seleted"));

const loadDataList = async () => {
  // listCountry.value = []
  let data = "";

  data = {
    shopUid: key.uid,
    lastUpdate: '0',
  };

  const payload = data;
  let list = []
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationListAllFetch(payload);
  // console.log("response : " + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      // item.value = response.data.data.list;
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list

        // console.log("loadDataList : " + JSON.stringify(list));
        childList.value = []
        list.forEach(function (entry) {
          entry['key'] = entry['uid'];
          entry['label'] = entry['name'];
        });
        setTree(list)
      }
    }
  }
};

loadDataList();

const onExpanded = (expandedItem) => {
  // console.log("onExpanded : " + JSON.stringify(expandedItem));
  // const lazyLoadedItems = fetchFromApi(...);
  // expandedItem.children.push(...lazyLoadedItems)
  // if (!expandedItem.children.children) {

  setTimeout(() => {
    loading.value = true
    expand.value = expandedItem
    // findChild(expandedItem.uid);
    expandedKeys.value[expandedItem.key] = true;
    // console.log("expandedKeys  : " + JSON.stringify(expandedKeys.value));
  }, 500);
  // }



}

const onCollapsed = (collapsedItem) => {

  setTimeout(() => {
    loading.value = true
    let tb = []
    tb.push(collapsedItem.children)
    // console.log(" tb  " + JSON.stringify(tb));
    if (tb.length > 0) {
      const ids = [];
      JSON.stringify(tb, (key, value) => {
        if (key === "uid") ids.push(value);
        return value;
      });
      // console.log(" arr  " + JSON.stringify(ids));
      let remove = (obj, arr) => {
        let output = [];
        for (const [key, value] of Object.entries(obj)) {
          if (!arr.includes(key)) {
            output.push([key, value]);
          }
        }
        return Object.fromEntries(output);
      }
      expandedKeys.value = remove(expandedKeys.value, ids)
      // console.log(remove(expandedKeys.value, ids));
      // console.log(" af  " + JSON.stringify(expandedKeys.value));
    }

    loading.value = false
  }, 500);

}

const onItemSelected = (items) => {
  selected.value = items
  // console.log('selected : ' + JSON.stringify(selected.value));
  // console.log('item : ' + JSON.stringify(item.value));
}



function addClick(node) {
  // console.log("addClick : " + JSON.stringify(node));
  addlocation(node);
}

function editClick(node) {
  // console.log("editClick : " + JSON.stringify(node));
  updatelocation(node)
}
function delClick(node) {
  // console.log("delClick : " + JSON.stringify(node));
  deletelocation(node)
}




async function addlocation(node) {
  let data = "";
  data = {
    shopCode: key.code,
    shopUid: key.uid,
    name: node.newName,
    parentUid: node.uid,
  };

  const payload = data;
  // console.log('addlocation : ' + JSON.stringify(payload));
  // const response = await store.dispatch("locationAddFetch", payload);
  const response = await storel.locationAddFetch(payload);
  // console.log('addlocation : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let num = Number(node.version) + 1
        // console.log('node.version : ' + num);
        selected.value.version = num
        updateTree(response.data.data, 'add')
        swal("บันทึกสำเร็จ", "", "success");
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updatelocation(node) {
  let data = "";
  data = {
    shopUid: key.uid,
    uid: node.uid,
    name: node.newName,
    version: node.version,
  };

  const payload = data;
  // console.log('updatelocation : ' + JSON.stringify(payload));
  // const response = await store.dispatch("locationUpdateFetch", payload);
  const response = await storel.locationUpdateFetch(payload);
  // console.log('updatelocation : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        updateTree(response.data.data, 'edit')
        swal("บันทึกสำเร็จ", "", "success");
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletelocation(node) {
  let data = "";
  data = {
    shopUid: key.uid,
    uid: node.uid,
  };

  const payload = data;
  // console.log('deletelocation : ' + JSON.stringify(payload));
  const response = await storel.locationDeleteFetch(payload);
  // console.log('deletelocation : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        updateTree(node.uid, 'del')
        swal("บันทึกสำเร็จ", "", "success");
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


function updateTree(response, process) {

  if (process === 'add') {
    const nodes = {
      uid: response.uid,
      name: response.name,
      level: response.level,
      parentUid: response.parentUid,
      version: response.version,
      code: response.code,
      children: []
    };
    const tb = []
    tb.push(nodes);
    selected.value.children.push(...tb)
  } else if (process === 'edit') {
    selected.value.name = response.name
    selected.value.version = response.version
  } else if (process === 'del') {
    const identity = x => x
    const update = (t = {}, k = "", f = identity) => // <- immutable update
      t[k] == null
        ? t
        : { ...t, [k]: f(t[k]) }

    const remove = (t = [], uid = 0) =>
      t.flatMap(v => remove1(v, uid))

    const remove1 = (t = {}, uid = 0) =>
      t.uid === uid
        ? remove(t.children, uid)
        : [update(t, "children", c => remove(c, uid))] // <-- update
    item.value = remove(item.value, response)
    // console.log('remove :  ' + JSON.stringify(item.value))
  }


}


function setTree(table) {

  const parentMap = {};
  const root = [];
  // Map parent positions
  table.forEach((el, i) => {
    parentMap[el.uid] = i;
    el.children = [];
  });

  table.forEach(({ uid, parentUid, code, name, level, children, version, key, label }) => {
    const insert = { uid, code, name, level, parentUid, children, version, key, label };
    if (parentUid === "0") {
      root.push(insert);
      return;
    }
    table[parentMap[parentUid]].children.push(insert);
  });

  // console.log('tb 1 :  ' + JSON.stringify(root));
  item.value = root

}

</script>

<style lang="css" scoped>
/* .bold {
  font-weight: 800 !important;
} */
</style>
