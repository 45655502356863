/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCloseCashStore = defineStore("closecash", {
  actions: {
    async closeCashListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "closingDateTime:ge": payload.start,
          "closingDateTime:le": payload.end,
          cashAccUid: payload.cashAccUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeCashInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeCashOpenBalanceFetch(payload) {
      const body = {
        cashAccUid: payload.cashAccUid,
        closingDateTime: payload.closingDateTime, //"2024-11-10T15:00:00",
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/open-balance";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeCashAddFetch(payload) {
      const body = {
        cashAccUid: payload.cashAccUid,
        closingDateTime: payload.closingDateTime, //"2024-11-10T15:00:00",
        remark: payload.remark,
        cashDetails: payload.cashDetails,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeCashUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        cashAccUid: payload.cashAccUid,
        closingDateTime: payload.closingDateTime,
        totalBillCount: payload.totalBillCount,
        totalAmount: payload.totalAmount,
        remark: payload.remark,
        fundStatus: payload.fundStatus,
        approveBy: payload.approveBy,
        approveDate: payload.approveDate,
        version: payload.version,
        cashDetails: payload.cashDetails,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async closeCashNewOpenshopFetch(payload) {
      const body = {
        cashAccUid: payload.cashAccUid,
        closingDateTime: payload.closingDateTime, //"2024-11-10T15:00:00",
        remark: "Open the Days",
        cashDetails: payload.cashDetails,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/new/open-shop";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async closeCashApproveFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/approve/" + payload.uid;
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeCashDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/cash/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
