/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useDocumentStore = defineStore("document", {
  actions: {
    async uploadFetch(payload) {
      const body = payload.formdata;
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/upload";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getinfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getFileFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/get/file/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async addFetch(payload) {
      const body = {
        name: payload.name,
        data: payload.data,
        forGroupUid: payload.forGroupUid, // required for platform user, assign this document to which group
        forShopUid: payload.forShopUid, // required for platform user, assign this document to wihic shop
        publicAccess: true, // required for platform user, this document is public for all shops
      };
      // console.log("addFetch : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async updateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        mimeType: "",
        data: payload.data,
        forGroupUid: "",
        forShopUid: "",
        publicAccess: true,
        version: payload.version,
      };
      // console.log("updateFetch : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async deleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/doc-svc/document/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
