/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCustomerTypeStore = defineStore("customertype", {
  actions: {
    async customertypeListFetch(payload) {
      // const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
      // console.log("keydd : " + key);

      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async customertypeInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async customertypeAddFetch(payload) {
      const body = {
        name: payload.name,
        creditLimit: payload.creditLimit,
        typeCategory: payload.typeCategory,
        shopUid: payload.shopUid,
        allowMenus: payload.allowMenus,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async customertypeUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        creditLimit: payload.creditLimit,
        typeCategory: payload.typeCategory,
        version: payload.version,
        shopUid: payload.shopUid,
        allowMenus: payload.allowMenus,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async customertypeDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
