<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="bmTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" aria-selected="true" @click="onClickTab('1')">
              {{ $t("apps.ghistory") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="tab2-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab"
              aria-controls="tab2" aria-selected="false" @click="onClickTab('2')">
              {{ $t("apps.gticketchain") }}
            </button>
          </li>
        </ul>

        <div id="bmTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("apps.ghistory") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">

                  <div class="col-lg-4  mt-0">
                    <fieldset>
                      <div class="item">
                        <material-checkbox v-model="time" :checked="time" name="autoSelect" @change="onserachDate">
                          <label class="form-label m-0">{{ $t("content.sdate") }}</label>
                        </material-checkbox>
                        <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                          :max-date="new Date()" :enable-time-picker="true" month-name-format="long" :clearable="false"
                          fluid :disabled="!time" :text-input="textInputOptions" />
                      </div>
                    </fieldset>
                  </div>


                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="true" month-name-format="long" :clearable="false"
                      fluid :disabled="!time" :text-input="textInputOptions" />
                  </div>

                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus"
                        :disabled="serial_search !== ''">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" :disabled="serial_search !== ''" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("apps.gtransaction") }}</label>
                    <select class="form-select" v-model="tx_search" :disabled="serial_search !== ''" @change="changeTx">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="NORMAL">{{ $t("content.cintrate1") }}</option>
                      <option value="MIGRATION">{{ $t("content.transmig") }}</option>
                    </select>
                  </div>

                  <div v-if="tx_search === 'MIGRATION'" class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.amnbiz") }}</label>
                    <select class="form-select" v-model="type_search" :disabled="serial_search !== ''">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="INTEREST">{{ $t("content.actpawn2") }}</option>
                      <option value="INC_PRINCIPLE">{{ $t("content.actpawn3") }}</option>
                      <option value="DEC_PRINCIPLE">{{ $t("content.actpawn4") }}</option>
                    </select>
                  </div>

                  <div v-else class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.amnbiz") }}</label>
                    <select class="form-select" v-model="type_search" :disabled="serial_search !== ''">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="PAWN">{{ $t("content.actpawn1") }}</option>
                      <option value="INTEREST">{{ $t("content.actpawn2") }}</option>
                      <option value="INC_PRINCIPLE">{{ $t("content.actpawn3") }}</option>
                      <option value="DEC_PRINCIPLE">{{ $t("content.actpawn4") }}</option>
                      <option value="REDEEM">{{ $t("content.actpawn5") }}</option>
                    </select>
                  </div>


                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.status") }}</label>
                    <select class="form-select" v-model="search_status">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option value="FINISH" selected>{{ $t("content.status_finish") }}</option>
                      <option value="FREEZE" selected>{{ $t("content.status_freeze") }}</option>
                      <option value="TERMINATE" selected>{{ $t("content.status_termi") }}</option>
                    </select>
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.serial") }}</label>
                    <material-input type="text" v-model="serial_search" @input="resetinput" />
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadDataHistoryApi" :disabled="(!time && cusName_search === '' && tk_search === '' && serial_search === '') || monthDiff()
                          || (time && start_date > end_date)">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ search }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <label class="form-label m-0 me-2">{{ $t("content.expfile") }} :</label>
                    <div>
                      <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                        class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                        <i class="material-icons material-symbols-outlined">{{ i.ic }}</i>
                        {{ i.nm }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.amnticketold") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.allweight") }}</th>
                          <th>{{ $t("content.allqty") }}</th>
                          <th>{{ $t("content.amnpawnvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>
                            {{ $t("content.actpawn3") }} /
                            {{ $t("content.actpawn4") }}
                          </th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.assessor") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listhistory" :key="index"
                          :class="row.status !== 'TERMINATE' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.prevRefNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ทำแทน)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ทำแทน)
                              </span>
                            </span>
                            <span v-else-if="row.shopRepresent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ออนไลน์)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ออนไลน์)
                              </span>
                            </span>
                            <span v-else-if="row.changeName">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType)
                                }},เปลี่ยนชื่อ)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (เปลี่ยนชื่อ)
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }})
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.type !== 'REDEEM'">
                              {{ formatNumberDecimalString(row.newPawnValue) }}
                            </span>
                            <span v-else>
                              {{ formatNumberDecimalString(row.pawnValue) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.roundInterest) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.diffValue) }}</td>
                          <td class="text-sm font-weight-normal">

                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.shopManagerName }}</td>
                          <td class=" text-sm font-weight-normal text-center">
                            {{ checkProcess(row.status) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" :title="chain"
                              @click="openListChain(row)">
                              <i class="material-icons material-symbols-outlined">arrow_forward</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listhistory.length === 0">
                          <td colspan="14" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="15" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listhistory.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab2" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 style="display: inline-block; width: auto">
                    {{ $t("apps.gticketchain") }}
                  </h5>
                  <div class="d-flex justify-content-end align-items-center">
                    <label class="form-label m-0 me-2">{{ $t("content.expfile") }} :</label>
                    <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                      class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                      <i class="material-icons material-symbols-outlined">{{ i.ic }}</i>
                      {{ i.nm }}
                    </material-button>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.trans") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.allweight") }}</th>
                          <th>{{ $t("content.allqty") }}</th>
                          <th>{{ $t("content.allprice") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listticketchain" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPageT - 1) * perPageT +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ทำแทน)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ทำแทน)
                              </span>
                            </span>
                            <span v-else-if="row.shopRepresent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ออนไลน์)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ออนไลน์)
                              </span>
                            </span>
                            <span v-else-if="row.changeName">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType)
                                }},เปลี่ยนชื่อ)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (เปลี่ยนชื่อ)
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }})
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listticketchain.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="10" style="text-align: right">Total Rows : {{ totalRows_sT }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listticketchain.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageT" :total-items="totalRowsT"
                          :items-per-page="perPageT" @click="onClickHandlerT" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <!-- Search Customer Dialog -->
          <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="preview-table">
                    <section class="panel">
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                            <th>#</th>
                            <th>{{ $t("content.cnameth") }}</th>
                            <th>{{ $t("content.cnameen") }}</th>
                            <th>{{ $t("content.docno") }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row-search">
                            <td></td>
                            <td>
                              <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                            </td>
                            <td>
                              <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                            </td>
                            <td>
                              <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                            </td>
                            <td>
                              <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                                :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                                @click="loadCustomerAfListApi">
                                <i class="material-icons material-symbols-outlined">search</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                            <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                            <td>{{ row.nameTh }}</td>
                            <td>{{ row.nameEn }}</td>
                            <td colspan="2">{{ row.docNumber }}</td>
                          </tr>
                          <tr v-if="customerlist.length === 0">
                            <td colspan="5" class="text-sm font-weight-normal">
                              <p style="text-align: center">No Record Found</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                </div>

                <div class="modal-footer">
                  <pre></pre>
                  <div style="display: flex; justify-content: flex-end">
                    <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                            :items-per-page="perPageC" @click="onClickHandlerC" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <!-- Detail Modal -->
          <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">

                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                        <div class="carousel-indicators" id="demo1">
                        </div>
                        <div class="carousel-inner" id="demo">
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                          data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                          data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-2">
                      <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                        <div>
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>

                                <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                  <span v-if="workcaseDetail.tkRefNumber !== ''">
                                    {{ workcaseDetail.tkRefNumber }} &nbsp;
                                    <span @click='copyBtn()' style="cursor: pointer">
                                      <i class="inline-icon material-icons">content_copy</i>
                                    </span>
                                  </span>
                                  <span v-else>-</span>
                                </td>
                                <td class="fs-14 text-bold">
                                </td>

                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                  {{ checkTransaction(workcaseDetail.transactionType) }}
                                </td>

                                <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                  <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                    {{ tk_status }}
                                  </span>
                                  <span
                                    v-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                    style="color:red;">
                                    {{ tk_status }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount === 0">
                                <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                  format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.amnduedate") }} :
                                  <span style="color:red;">
                                    {{ format_date(workcaseDetail.dueDate) }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                  format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2">
                                  {{ $t("content.amnduedate") }} :
                                  <span style="color:red;">
                                    {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                      format_date(workcaseDetail.postponeDate) }})
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2">
                                  {{ $t("content.deferpast") }} :
                                  <span style="text-decoration: underline;color:red; cursor: pointer">
                                    {{ workcaseDetail.postponeCount }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.monthlyprof") }} : {{
                                    formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                                </td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.currentprof") }} :
                                  <span style="color:red;">
                                    {{ formatNumberDecimalString(int_cal) }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.prointerest") }} :
                                  <span
                                    v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                    -
                                  </span>
                                  <span style="color:blue;" v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                  </span>
                                </td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.periodpromotion") }} :
                                  <span v-if="workcaseDetail.promotionDuration === 0">
                                    -
                                  </span>
                                  <span style="color:blue;" v-else>
                                    {{ workcaseDetail.promotionDuration }} เดือน
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                  {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.pawnvalpv") }} :
                                  <span v-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <!-- <span v-if="workcaseDetail.transactionType === 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <span v-else-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span> -->
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.actpawn3") }}/{{ $t("content.actpawn4") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  <!-- <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span> -->
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                  <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                  </span>
                                  <span v-else style="color:red;">
                                    มี ({{ workcaseDetail.lostCount }})
                                  </span>
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                  <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                  </span>
                                  <span v-else style="color:red;">
                                    {{ workcaseDetail.lostRefNumber }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.represent || workcaseDetail.shopRepresent">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                  <span style="color:red;">
                                    {{ workcaseDetail.workItems[0].customerName }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.changeName">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                  <span style="color:red;">
                                    {{ workcaseDetail.workItems[0].tkCustomerName }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                  <span v-if="location !== ''">
                                    {{ location.fullName }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.stockno") }} :
                                  <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                    {{ workcaseDetail.ticket.stockNumber }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.migraref") }} :
                                  <span v-if="workcaseDetail.migrateRefNumber !== null">
                                    {{ workcaseDetail.migrateRefNumber }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <span v-if="listinfo.length !== 0">
                            <table class="table table-sm table-borderless">
                              <tbody>
                                <tr>
                                  <td class="fs-14 text-bold">
                                    <span style="text-decoration:underline;">
                                      {{ $t("content.amnassetdetail") }}
                                    </span>
                                  </td>
                                </tr>

                                <tr v-for="(row, index) in listinfo" :key="index">
                                  <td class="fs-14 text-bold">
                                    {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                          </span>
                          <span v-else>
                            <tr>
                              <td class="fs-14 text-bold">
                                <span style="text-decoration:underline;">
                                  {{ $t("content.amnassetdetail") }}
                                </span>
                                : -
                              </td>

                            </tr>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="paymentE !== ''" />
                  <div class="col-12 mt-2" v-if="paymentE !== ''">
                    <div class="fs-14 text-bold">
                      <span style="text-decoration:underline;">
                        {{ $t("content.amnfinanceinfo") }}
                      </span>
                    </div>
                    <pre></pre>
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t("content.ticketno") }}</th>
                              <th>{{ $t("content.amnbiz") }}</th>
                              <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnincome") }}
                                </span>
                              </th>
                              <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnoutcome") }}
                                </span>
                              </th>
                              <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.fee") }}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th colspan="3"></th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncreditcard") }}
                                </span>

                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncheque") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amncreditcard") }}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th colspan="3"></th>
                              <th style="background-color:#dfffdd;"></th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  QR Code
                                </span>
                              </th>
                              <th colspan="2" style="background-color:#dfffdd;"></th>
                              <th style="background-color:#fecac7;"></th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amntransfer_promt_mobile") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amntransfer_promt_taxid") }}
                                </span>
                              </th>
                              <th style="background-color:#CFEBFD;"></th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  QR Code
                                </span>
                              </th>
                              <th style="background-color:#CFEBFD;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, index) in paymentE.workCases" :key="index">
                              <template v-if="row.ticket !== null">
                                <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                                </td>
                                <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                                <td class="text-sm font-weight-normal text-center">{{
                                  checkTransaction(row.transactionType) }}</td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[0].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[1].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[6].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[6].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[2].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[2].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[3].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[3].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[0].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[1].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[4].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[4].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[5].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[5].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[0].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[1].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[6].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[6].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[2].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import Modal from 'bootstrap/js/dist/modal';
import ProgressSpinner from 'primevue/progressspinner';
import { reactive, ref, computed, onMounted, inject, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Textarea from 'primevue/textarea';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"

import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useInterestStore } from '@/stores/interest'
import { useLocationStore } from '@/stores/location'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useDocumentStore } from '@/stores/document'

const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const storei = useInterestStore()
const storel = useLocationStore()
const storep = usePaymentGroupStore()
const stored = useDocumentStore()


const { t } = useI18n();
const swal = inject("$swal");





const exportFile = reactive([
  {
    cl: "info",
    cs: "me-2",
    ic: "picture_as_pdf",
    nm: "pdf",
    ac: "",
  },
  {
    cl: "info",
    cs: "",
    ic: "table_rows",
    nm: "excel",
    ac: "",
  },
]);


const cancel = computed(() => t("buttons.canc"));
const confirm = computed(() => t("buttons.conf"));
const chain = computed(() => t('buttons.seed') + t("apps.gticketchain"));
const copy = computed(() => t("buttons.copyData"));
const search = computed(() => t("buttons.sear"));
const save = computed(() => t("buttons.save"));

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY HH:mm');
}

const textInputOptions = {
  format: 'dd/MM/yyyy HH:mm'
};



const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageT = ref(1);
const totalRowsT = ref(1);
const perPageT = ref(10);
const totalRows_sT = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const cusUid_search = ref('');
const cusName_search = ref('');
const tk_search = ref('');
const type_search = ref('');
const serial_search = ref('');
const search_status = ref('');
const tx_search = ref('');


const listprint = ref([]);
const listhistory = ref([]);
const listticketchain = ref([]);
const listinfo = ref([]);
const customerlist = ref([])
const info_cat = ref('');
const workcaseDetail = ref(null);
const chain_uid = ref('');
const tk_status = ref('');
const int_cal = ref(0);
const location = ref('');
const paymentE = ref('');
const time = ref(false);


const images = ref([])
const images_l = ref([])

const shop_detail = ref({})

const payment_l = ref([])

const loading = ref(false);

const key = JSON.parse(localStorage.getItem("shop_seleted"));


const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};


const onClickHandlerT = (page) => {
  updatePaginationT()
};

const onClickHandlerC = (page) => {
  updatePaginationC()
};



function copyBtn() {
  navigator.clipboard.writeText(workcaseDetail.value.tkRefNumber);
}

const onserachDate = () => {
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
};


function cancelBtn() {
  time.value = false;
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  type_search.value = '';
  serial_search.value = '';
  currentPage.value = 1
  listhistory.value = []
  listticketchain.value = []
  totalRows_s.value = ''
  search_status.value = ''
  tx_search.value = ''
}

function resetinput() {
  time.value = false;
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  type_search.value = '';
  search_status.value = '';
  tx_search.value = ''
}

function changeTx() {
  type_search.value = '';
}


function monthDiff() {
  // let diff = ''
  let pro = false
  // diff = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12 + (end_date.value.getMonth() - start_date.value.getMonth())
  // // diff = end_date.value.getMonth() - start_date.value.getMonth() +
  // //   (12 * (end_date.value.getFullYear() - start_date.value.getFullYear()))
  var months;
  months = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12;
  months += end_date.value.getMonth() - start_date.value.getMonth();

  if (time.value && months > 3) {
    pro = true
  }
  return pro
}

const loadDataHistoryApi = async () => {
  // console.log("start_date  : " + moment(new Date(start_date.value)).format('YYYY-MM-DDThh:mm:00') + " end_date : " + moment(new Date(end_date.value)).local());
  let data = "";
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00')
    ed = moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00')
  }
  if (cusUid_search.value !== '' || tk_search.value || type_search.value !== '' || serial_search.value !== ''
    || search_status.value !== '' || tx_search.value !== '') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
    type: type_search.value,
    serialNumber: serial_search.value,
    shopUid: key.uid,
    status: search_status.value,
    txFilter: tx_search.value


  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storewc.workCaseHistoryFetch(payload);
  // console.log("loadHistory : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  console.log("workCaseInfo p : " + JSON.stringify(payload));
  const response = await storewc.workCaseinfoFetch(payload);
  console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}


async function getListChain() {
  let data = "";
  data = {
    index: currentPageT.value - 1,
    size: perPageT.value,
    uid: chain_uid.value,
  };
  const payload = data;

  const response = await storewc.workCaseListChainFetch(payload);
  console.log("getListChain : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listticketchain.value = response.data.data.list;
        totalRowsT.value = response.data.data.ext.total
        totalRows_sT.value = formatNumberString(totalRowsT.value)
      }
      onClickTab('2')
    }
  }
}

async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  // console.log("calIntInt p : " + JSON.stringify(payload));
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt r : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("calIntInt : " + JSON.stringify(res));
        // int_cal.value = Number(res.promoInterest) + Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("int_cal : " + int_cal.value);
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: '',
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}


async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);

  const response = await storep.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}

function filterCat(str) {
  let side = ''
  let name = listprint.value.filter((c) => c.uid === str);
  if (name.length > 0) {
    side = name[0].name
  }
  return side
}


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function checkProcess(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'เสร็จสิ้น'
  } else if (str === 'ACTIVE') {
    side = 'กำลังดำเนินการ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'TERMINATE') {
    side = 'ยกเลิก'
  }
  return side
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' },
  { name: 'QR_CODE' },
  ]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].feeReceive
      // fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}




async function onClickTab(val) {
  if (val === '1') {
    if ((time.value && monthDiff()) || cusName_search.value !== '' && tk_search.value !== '' && serial_search.value !== '') {
      loadDataHistoryApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
  }
}


async function setfirstTab() {
  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}


async function setsecondTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.add('show', 'active')
  }
}

async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  cusUid_search.value = item.uid
  cusName_search.value = item.nameTh
  document.getElementById("closeModal").click();
}

async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePagination() {
  listhistory.value = []
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00')
    ed = moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00')
  }
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
    type: type_search.value,
    serialNumber: serial_search.value,
    shopUid: key.uid,
    status: search_status.value,
    txFilter: tx_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storewc.workCaseHistoryFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function updatePaginationT() {
  listticketchain.value = []
  let data = "";
  data = {
    index: currentPageT.value - 1,
    size: perPageT.value,
    uid: chain_uid.value,
  };
  const payload = data;

  const response = await storewc.workCaseListChainFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listticketchain.value = response.data.data.list;
        totalRowsT.value = response.data.data.ext.total
        totalRows_sT.value = formatNumberString(totalRowsT.value)
      }
    }
  }

}



async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}


function setGallery() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..."  />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;
  document.getElementById("demo").innerHTML = x;

}


async function openDetail(item) {
  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  paymentE.value = ''
  // console.log('item : ' + JSON.stringify(item));
  await getworkCaseInfo(item.uid)
  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await detailPayment(workcaseDetail.value.paymentGroupUid)

    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()

    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}

async function openListChain(item) {
  chain_uid.value = item.uid
  currentPageT.value = 1
  listticketchain.value = []
  await getListChain()
}


async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function getTicketImage() {
  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }
    if (url.length > 0) {
      await getFile(url)
      // setTimeout(3000);
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}







onMounted(() => {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
});


</script>

<style lang="scss" scoped>
.inline-icon {
  display: inline-flex;
  vertical-align: top;
  font-size: 20px !important;
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  max-width: 990px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.btnClose {
  border-color: transparent !important;
  background-color: white !important;
  vertical-align: baseline;

  i.material-icons {
    padding-top: 8px;
    color: gray-600;
  }
}

.grid-containerP {
  max-width: 990px;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.wrapper {
  position: relative;
}

.myLayer {
  position: absolute;
  /* becomes a layer */
  // left: 0;
  /* relative to its parent wrapper */
  top: -18px;
  right: -23px;
  /* relative to its parent wrapper */
  z-index: 2;
  font-size: 20px;
}
</style>
