/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useInteresTiertStore = defineStore("interesttier", {
  actions: {
    async interestTierListByIntUidFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest-tier/list/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestTierAddFetch(payload) {
      const body = {
        intUid: payload.intUid,
        shopUid: payload.shopUid,
        startValue: payload.startValue,
        endValue: payload.endValue,
        rate: payload.rate,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest-tier/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestTierUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        intUid: payload.intUid,
        shopUid: payload.shopUid,
        startValue: payload.startValue,
        endValue: payload.endValue,
        rate: payload.rate,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest-tier/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async interestTierDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest-tier/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
