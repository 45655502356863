<template>
	<div class="container-fluid">
		<div class="row mb-5">
			<div class="col-12">

				<div class="card mt-2">
					<div class="card-header mb-0">
						<h5>{{ $t("content.amntxnoverall") }}</h5>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-lg-3">
								<label class="form-label ms-0">{{ $t("content.sdate") }}</label>
								<VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
									:max-date="new Date()" :enable-time-picker="true" month-name-format="long"
									:clearable="false" :text-input="textInputOptions" fluid />
							</div>

							<div class="col-lg-3">
								<label class="form-label ms-0">{{ $t("content.edate") }}</label>
								<VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
									:max-date="new Date()" :enable-time-picker="true" month-name-format="long"
									:clearable="false" :text-input="textInputOptions" fluid />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t("content.names") }}</label>
								<div class="input-group">
									<input v-model="cusName_search" type="text" class="form-control"
										aria-describedby="searchName" readonly />
									<button class="btn btn-primary" type="button" @click="openDialogCus">
										<i class="material-icons material-symbols-outlined">search</i>
									</button>
									<button id="searchNameCus" class="btn btn-primary" type="button"
										data-bs-toggle="modal" data-bs-target="#searchNameModal" v-show="false">
									</button>
								</div>
							</div>
						</div>

						<div class="row mt-4">
							<div class="col-3 d-flex justify-content-between align-items-center"></div>
							<div class="col-9 d-flex justify-content-end align-items-center">
								<div class="me-3">
									<material-button id="cardSearch" color="primary"
										class="float-lg-start float-sm-end mb-3"
										:disabled="monthDiff() || start_date > end_date" @click="loadDataHistoryApi">
										<i class="material-icons material-symbols-outlined">search</i>
										{{ $t("buttons.sear") }}
									</material-button>
								</div>
								<div class="me-3">
									<material-button id="cardSearch" color="danger"
										class="float-lg-start float-sm-end mb-3" @click="cancelBtn">
										<i class="material-icons material-symbols-outlined">close</i>
										{{ cancel }}
									</material-button>
								</div>
							</div>
						</div>
						<hr />
						<div class="row">
							<div class="table-responsive">
								<table class="table table-flush">
									<thead class="thead-light">
										<tr>
											<th class="align-top">#</th>
											<th class="align-top">{{ $t("content.amndatetime") }}</th>
											<th class="align-top">{{ $t("content.amnfinanceno") }}</th>
											<th class="align-top">{{ $t("content.amnticketold") }}</th>
											<th class="align-top">{{ $t("content.ticketno") }}</th>
											<th class="align-top">{{ $t("content.amnbiz") }}</th>
											<th class="align-top">{{ $t("content.amnweightvalall") }}</th>
											<th class="align-top">{{ $t("content.amnqtyunitall") }}</th>
											<th class="align-top">{{ $t("content.amnanalysval") }}</th>
											<th class="align-top">{{ $t("content.gpay") }}</th>
											<th class="align-top">{{ $t("content.status") }}</th>
											<th colspan="5" class="align-top text-center"
												style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amnincome") }}
												</span>
											</th>
											<th colspan="4" class="align-top text-center"
												style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amnoutcome") }}
												</span>
											</th>
											<th colspan="4" class="align-top text-center"
												style="background-color:#CFEBFD;">
												<span style="color:black;">
													{{ $t("content.fee") }}
												</span>
											</th>
											<th class="align-top"></th>
										</tr>

										<tr>
											<th colspan="11"></th>
											<th class="align-top text-center" style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amncash") }}
												</span>
											</th>
											<th colspan="2" class="align-top text-center"
												style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amnbanktransfer") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amncreditcard") }}
												</span>

											</th>
											<th class="align-top text-center" style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amncheque") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amncash") }}
												</span>
											</th>
											<th colspan="3" class="align-top text-center"
												style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amnbanktransfer") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#CFEBFD;">
												<span style="color:black;">
													{{ $t("content.amncash") }}
												</span>
											</th>
											<th colspan="2" class="align-top text-center"
												style="background-color:#CFEBFD;">
												<span style="color:black;">
													{{ $t("content.amnbanktransfer") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#CFEBFD;">
												<span style="color:black;">
													{{ $t("content.amncreditcard") }}
												</span>
											</th>
											<th class="align-top text-center"></th>
										</tr>
										<tr>
											<th colspan="11"></th>
											<th style="background-color:#dfffdd;"></th>
											<th class="align-top text-center" style="background-color:#dfffdd;">
												<span style="color:black;">
													{{ $t("content.amnbankacc") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#dfffdd;">
												<span style="color:black;">
													QR Code
												</span>
											</th>
											<th colspan="2" style="background-color:#dfffdd;"></th>
											<th style="background-color:#fecac7;"></th>
											<th class="align-top text-center" style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amnbankacc") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amntransfer_promt_mobile") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#fecac7;">
												<span style="color:black;">
													{{ $t("content.amntransfer_promt_taxid") }}
												</span>
											</th>
											<th style="background-color:#CFEBFD;"></th>
											<th class="align-top text-center" style="background-color:#CFEBFD;">
												<span style="color:black;">
													{{ $t("content.amnbankacc") }}
												</span>
											</th>
											<th class="align-top text-center" style="background-color:#CFEBFD;">
												<span style="color:black;">
													QR Code
												</span>
											</th>
											<th style="background-color:#CFEBFD;"></th>
											<th class="align-top text-center"></th>
										</tr>
									</thead>

									<tbody v-for="(i, pay1) in listhistory" :key="pay1" :data-group-index="pay1">
										<tr :class="i.roundTotalPay !== 0 ? 'bg-payment' :
											i.roundTotalReceive !== 0 ? 'bg-receive' : 'bg-nonemi'">
											<td class="text-sm font-weight-normal" colspan="24">
												<div class="group-tickect">
													<h6 class="mb-0">
														<b>{{ t("content.amngroupticket") }} #{{ (currentPage - 1) *
															perPage + pay1 + 1 }}
															{{ i.cusName }}
															( {{ $t("content.amntotalval") }}
															<span v-if="i.roundTotalPay > 0">
																{{ formatNumberDecimalString(i.roundTotalPay) }}
															</span>
															<span v-else>
																{{ formatNumberDecimalString(i.roundTotalReceive) }}
															</span>
															{{ $t("content.curency") }} -
															{{
																$t("content.amnallticket")
															}}
															: {{ countTicket(i.workCases) }} )
														</b>
													</h6>
												</div>
											</td>
											<td class="text-sm font-weight-normal text-center">
												<div class="group-tickect">
													<material-button color="info" variant="outline"
														class="rounded-circle">
														<i class="material-icons material-symbols-outlined">print</i>
													</material-button>
												</div>
											</td>
										</tr>

										<tr v-for="(row, index) in i.workCases" :key="index"
											:class="row.status != 'TERMINATE' ? '' : 'bg-working'">
											<template v-if="row.ticket !== null && row.type !== 'PAYMENT'">
												<td class="text-sm font-weight-normal text-center">{{
													formatNumberString(index + 1) }}</td>
												<td class="text-sm font-weight-normal">
													{{ format_datetime(row.createDate) }}
												</td>
												<td class="text-sm font-weight-normal">
													{{ i.refNumber }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													{{ row.prevRefNumber }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													{{ row.refNumber }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													{{ checkTransaction(row.type) }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													{{ formatNumberDecimalString(row.weight) }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													{{ formatNumberDecimalString(row.quantity) }}
												</td>
												<td class="text-sm font-weight-normal text-center">
													<span v-if="row.type !== 'REDEEM'">
														{{ formatNumberDecimalString(row.newPawnValue) }}
													</span>
													<span v-else>
														{{ formatNumberDecimalString(row.pawnValue) }}
													</span>
												</td>
												<td class="text-sm font-weight-normal text-center">
													<span v-if="row.migration">
														<span
															v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0"
															style="color:red;">
															{{ formatNumberDecimalString(row.totalPay) }}
														</span>
														<span v-else-if="row.totalReceive > 0" style="color:green;">
															{{ formatNumberDecimalString(row.totalReceive) }}
														</span>
														<span v-else style="color:gray;">
															{{ formatNumberDecimalString(row.totalReceive) }}
														</span>
													</span>
													<span v-else>
														<span
															v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0"
															style=" color:red;">
															{{ formatNumberDecimalString(row.totalPay) }}
														</span>
														<span v-else-if="row.totalReceive > 0" style="color:green;">
															{{ formatNumberDecimalString(row.totalReceive) }}
														</span>
														<span v-else style="color:gray;">
															{{ formatNumberDecimalString(row.totalReceive) }}
														</span>
													</span>

												</td>
												<td class=" text-sm font-weight-normal text-center">
													{{ checkProcess(row.status) }}
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receiveC > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receiveC) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receiveB > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receiveB) }}
													</span>
													<span v-else>
														-
													</span>
												</td>

												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receiveQR > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receiveQR) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receiveCC > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receiveCC) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receiveCH > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receiveCH) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payC > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payC) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payB > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payB) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payPM > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payPM) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payPT > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payPT) }}
													</span>
													<span v-else>
														-
													</span>
												</td>

												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_cash > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_cash) }}
													</span>
													<span v-else>
														-
													</span>
												</td>

												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_bank > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_bank) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_QR > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_QR) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_credit > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_credit) }}
													</span>
													<span v-else>
														-
													</span>
												</td>
												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receivePM > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receivePM) }}
													</span>
													<span v-else>
														-
													</span>
												</td> -->

												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_promtmo > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_promtmo) }}
													</span>
													<span v-else>
														-
													</span>

												</td> -->
												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.receivePT > 0" style="color:green;">
														{{ formatNumberDecimalString(i.receivePT) }}
													</span>
													<span v-else>
														-
													</span>
												</td> -->

												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_promttx > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_promttx) }}
													</span>
													<span v-else>
														-
													</span>

												</td> -->

												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payCH > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payCH) }}
													</span>
													<span v-else>
														-
													</span>
												</td> -->
												<!-- <td v-if="index == 0" class=" text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.fee_cheque > 0" style="color:blue;">
														{{ formatNumberDecimalString(i.fee_cheque) }}
													</span>
													<span v-else>
														-
													</span>

												</td> -->

												<!-- <td v-if="index == 0" class="text-sm font-weight-normal text-center"
													:rowspan="i.workCases.length" style="background-color: white;">
													<span v-if="i.payCC > 0" style="color:red;">
														{{ formatNumberDecimalString(i.payCC) }}
													</span>
													<span v-else>
														-
													</span>
												</td> -->

											</template>
										</tr>

									</tbody>
									<tbody v-if="listhistory.length === 0">
										<tr>
											<td colspan="24" class="text-sm font-weight-normal">
												<p style="text-align: center">No Record Found</p>
											</td>
										</tr>
									</tbody>

									<tfoot>
										<tr>
											<td colspan="11" class="font-weight-bold text-center">
												<span v-if="listhistory.length > 0">
													{{ t("content.gtotal") }}
												</span>
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ cashtt_receive }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ banktt_receive }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ qrtt_receive }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ credittt_receive }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ chequett_receive }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ cashtt_pay }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ banktt_pay }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ promtmott_pay }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ promttxtt_pay }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ cashtt_fee }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ banktt_fee }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ qrtt_fee }}
											</td>
											<td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ credittt_fee }}
											</td>

											<!-- <td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ promtmott_receive }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ promtmott_fee }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:green;">
												{{ promttxtt_receive }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ promttxtt_fee }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ chequett_pay }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:blue;">
												{{ chequett_fee }}
											</td> -->
											<!-- <td class="text-sm font-weight-normal text-center" style="color:red;">
												{{ credittt_pay }}
											</td> -->
											<td style="text-align: right">Total Rows : {{ totalRows_s }}
											</td>
										</tr>
										<tr v-if="listhistory.length > 0">

											<td colspan="4" class="font-weight-bold text-center"
												style="background-color: white;">
												{{ t("content.ttreceivem") }}
												<span style="color:green;">
													{{ totalreceive }}
												</span>
											</td>
											<td colspan="4" class="font-weight-bold text-center"
												style="background-color: white;">
												{{ t("content.ttpaym") }}
												<span style="color:red;">
													{{ totalpay }}
												</span>
											</td>
											<td colspan="4" class="font-weight-bold text-center"
												style="background-color: white;">
												{{ t("content.ttfee") }}
												<span style="color:blue;">
													{{ totalfee }}
												</span>

											</td>
											<td colspan="18" style="background-color: white;">
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
						<pre></pre>
						<div style=" display: flex; justify-content: flex-end">
							<ul v-show="listhistory.length > 0" class="pagination justify-content-end">
								<div class="project-container">
									<div class="example-one">
										<vue-awesome-paginate v-model="currentPage" :total-items="totalRows"
											:items-per-page="perPage" @click="onClickHandler" />
									</div>
								</div>
							</ul>
						</div>
					</div>
				</div>

				<!-- Search Customer Dialog -->
				<div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
					aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
								<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
									id="closeModal">
									<i class="material-icons material-symbols-outlined">close</i>
								</button>
							</div>

							<div class="modal-body">
								<div class="preview-table">
									<section class="panel">
										<table class="table table-striped table-responsive">
											<thead>
												<tr>
													<!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
													<th>#</th>
													<th>{{ $t("content.cnameth") }}</th>
													<th>{{ $t("content.cnameen") }}</th>
													<th>{{ $t("content.docno") }}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr class="row-search">
													<td></td>
													<td>
														<material-input v-model="nameThC" type="text"
															:label="t('content.cnameth')" />
													</td>
													<td>
														<material-input v-model="nameEnC" type="text"
															:label="t('content.cnameen')" />
													</td>
													<td>
														<material-input v-model="docNumberC" type="text"
															:label="t('content.docno')" />
													</td>
													<td>
														<material-button color="primary" variant="contain"
															class="rounded-circle ms-1" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnSearch"
															:disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
															@click="loadCustomerAfListApi">
															<i
																class="material-icons material-symbols-outlined">search</i>
														</material-button>
													</td>
												</tr>
												<tr v-for="(row, index) in customerlist" :key="index"
													@click="selectCusAf(row)">
													<td>{{ formatNumberString((currentPageC - 1) * perPageC + index
														+ 1)
														}}</td>
													<td>{{ row.nameTh }}</td>
													<td>{{ row.nameEn }}</td>
													<td colspan="2">{{ row.docNumber }}</td>
												</tr>
												<tr v-if="customerlist.length === 0">
													<td colspan="5" class="text-sm font-weight-normal">
														<p style="text-align: center">No Record Found</p>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
								</div>
							</div>

							<div class="modal-footer">
								<pre></pre>
								<div style="display: flex; justify-content: flex-end">
									<ul v-show="customerlist.length > 0" class="pagination justify-content-end">
										<div class="project-container">
											<div class="example-one">
												<vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
													:items-per-page="perPageC" @click="onClickHandlerC" />
											</div>
										</div>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Choices from "choices.js";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";

import moment from 'moment'

import FingerScan from "@/assets/img/pawn/finger_scan.png";

import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useCustomerStore } from '@/stores/customer'
import { useCashAccountStore } from '@/stores/cashaccount'

const storep = usePaymentGroupStore()
const storecus = useCustomerStore()
const storeca = useCashAccountStore()


const { t } = useI18n();
// const swal = inject("$swal");

///// computed
const cancel = computed(() => t("buttons.canc"));
const confirm = computed(() => t("buttons.conf"));
const startscan = computed(() => t("buttons.sscan"));
const newscan = computed(() => t("buttons.nscan"));


const fmNumber = (val) => {
	return Intl.NumberFormat("en-US").format(val);
};

const format = (date) => {
	return moment(String(date)).local().format('DD/MM/YYYY HH:mm');
}

const textInputOptions = {
	format: 'dd/MM/yyyy HH:mm'
};

const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const cusUid_search = ref('');
const cusName_search = ref('');

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const customerlist = ref([])
const listhistory = ref([]);
const listsummary = ref([]);

const paymentE = ref('');
const payment_l = ref([])

const cashtt_receive = ref('');
const cashtt_pay = ref('');
const banktt_receive = ref('');
const banktt_pay = ref('');
const promtmott_receive = ref('');
const promtmott_pay = ref('');
const promttxtt_receive = ref('');
const promttxtt_pay = ref('');
const chequett_receive = ref('');
const chequett_pay = ref('');
const credittt_receive = ref('');
const credittt_pay = ref('');
const cashtt_fee = ref('');
const banktt_fee = ref('');
const promtmott_fee = ref('');
const promttxtt_fee = ref('');
const chequett_fee = ref('');
const credittt_fee = ref('');
const totalpay = ref('');
const totalreceive = ref('');
const totalfee = ref('');
const qrtt_receive = ref('');
const qrtt_pay = ref('');
const qrtt_fee = ref('');

const key = JSON.parse(localStorage.getItem("shop_seleted"));


const onClickHandler = (page) => {
	updatePagination()
};

const onClickHandlerC = (page) => {
	updatePaginationC()
};

function cancelBtn() {
	start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
	end_date.value = new Date();
	cusUid_search.value = '';
	cusName_search.value = '';
	currentPage.value = 1
	listhistory.value = []
	totalRows_s.value = ''
	listsummary.value = []
	clearDatasummary()
}

function monthDiff() {
	// let diff = ''
	let pro = false
	var months;
	months = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12;
	months += end_date.value.getMonth() - start_date.value.getMonth();

	if (months > 3) {
		pro = true
		// console.log('m : ' + months + 'p : ' + pro)
	}
	return pro
}





const loadDataHistoryApi = async () => {
	// console.log("start_date  : " + moment(new Date(start_date.value)).format('YYYY-MM-DDThh:mm:00') + " end_date : " + moment(new Date(end_date.value)).local());
	let data = "";
	if (cusUid_search.value !== '') {
		currentPage.value = 1
	}
	data = {
		index: currentPage.value - 1,
		size: perPage.value,
		cusUid: cusUid_search.value,
		fromDate: moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00'),
		toDate: moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00'),
		shopUid: key.uid
	};

	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storep.paymentGroupHistoryFetch(payload);
	// console.log("loadOverall : " + JSON.stringify(response.data));
	let listd = []
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				clearDatasummary()
				listd = response.data.data.list.paymentGroup;
				listsummary.value = response.data.data.list.summary;
				listd.map(function (x) {
					x.payC = 0
					x.receiveC = 0
					x.payB = 0
					x.receiveB = 0
					x.payCC = 0
					x.receiveCC = 0
					x.payCH = 0
					x.receiveCH = 0
					x.payPM = 0
					x.receivePM = 0
					x.payPT = 0
					x.receivePT = 0
					x.fee_cash = 0
					x.fee_bank = 0
					x.fee_promtmo = 0
					x.fee_promttx = 0
					x.fee_cheque = 0
					x.fee_credit = 0
					x.receiveQR = 0
					x.payQR = 0
					x.fee_QR = 0
					return x
				});
				let ld = []
				for (let i = 0; i < listd.length; i++) {
					// console.log(i + ' : ' + JSON.stringify(listd[i]));
					if (listd[i].payments.length > 0) {
						filterPayType(listd[i].payments)
						if (payment_l.value.length > 0) {
							// console.log("payment_l : " + JSON.stringify(payment_l.value));
							listd[i].payC = payment_l.value[0].pay
							listd[i].receiveC = payment_l.value[0].receive
							listd[i].fee_cash = payment_l.value[0].fee
							listd[i].payB = payment_l.value[1].pay
							listd[i].receiveB = payment_l.value[1].receive
							listd[i].fee_bank = payment_l.value[1].fee
							listd[i].payCC = payment_l.value[2].pay
							listd[i].receiveCC = payment_l.value[2].receive
							listd[i].fee_credit = payment_l.value[2].fee
							listd[i].payCH = payment_l.value[3].pay
							listd[i].receiveCH = payment_l.value[3].receive
							listd[i].fee_cheque = payment_l.value[3].fee
							listd[i].payPM = payment_l.value[4].pay
							listd[i].receivePM = payment_l.value[4].receive
							listd[i].fee_promtmo = payment_l.value[4].fee
							listd[i].payPT = payment_l.value[5].pay
							listd[i].receivePT = payment_l.value[5].receive
							listd[i].fee_promttx = payment_l.value[5].fee
							listd[i].payQR = payment_l.value[6].pay
							listd[i].receiveQR = payment_l.value[6].receive
							listd[i].fee_QR = payment_l.value[6].fee
						}

					}
				}
				if (listsummary.value.length > 0) {
					setsummary()
				}

				listhistory.value = listd
				totalRows.value = response.data.data.ext.total
				totalRows_s.value = formatNumberString(totalRows.value)

				// console.log("listhistory : " + JSON.stringify(listhistory.value));
				// console.log("listsummary : " + JSON.stringify(listsummary.value));
			}
		}
	}

}

loadDataHistoryApi()


function setsummary() {
	cashtt_receive.value = '';
	cashtt_pay.value = '';
	banktt_receive.value = '';
	banktt_pay.value = '';
	promtmott_receive.value = '';
	promtmott_pay.value = '';
	promttxtt_receive.value = '';
	promttxtt_pay.value = '';
	chequett_receive.value = '';
	chequett_pay.value = '';
	credittt_receive.value = '';
	credittt_pay.value = '';
	cashtt_fee.value = '';
	banktt_fee.value = '';
	promtmott_fee.value = '';
	promttxtt_fee.value = '';
	chequett_fee.value = '';
	credittt_fee.value = '';
	qrtt_receive.value = '';
	qrtt_pay.value = '';
	qrtt_fee.value = '';
	totalpay.value = '';
	totalreceive.value = '';
	totalfee.value = '';

	let ttpay = 0
	let ttreceive = 0
	let ttfee = 0


	let ld = findtotal('CASH')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			cashtt_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			cashtt_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// cashtt_fee.value = formatNumberDecimalString(ld[0].totalFee)
			cashtt_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			// ttfee = ttfee + ld[0].totalFee
			ttfee = ttfee + ld[0].totalFeeReceive
		}
	}
	ld = findtotal('BANK_TRANSFER')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			banktt_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			banktt_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// banktt_fee.value = formatNumberDecimalString(ld[0].totalFee)
			banktt_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			// ttfee = ttfee + ld[0].totalFee
			ttfee = ttfee + ld[0].totalFeeReceive
		}
	}
	ld = findtotal('PROMPT_PAY_MOBILE')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			promtmott_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			promtmott_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// promtmott_fee.value = formatNumberDecimalString(ld[0].totalFee)
			promtmott_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			ttfee = ttfee + ld[0].totalFeeReceive
			// ttfee = ttfee + ld[0].totalFee
		}
	}
	ld = findtotal('PROMPT_PAY_TAX_ID')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			promttxtt_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			promttxtt_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// promttxtt_fee.value = formatNumberDecimalString(ld[0].totalFee)
			promttxtt_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			ttfee = ttfee + ld[0].totalFeeReceive
			// ttfee = ttfee + ld[0].totalFee
		}
	}
	ld = findtotal('CHEQUE')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			chequett_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			chequett_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// chequett_fee.value = formatNumberDecimalString(ld[0].totalFee)
			chequett_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			ttfee = ttfee + ld[0].totalFeeReceive
			// ttfee = ttfee + ld[0].totalFee
		}
	}
	ld = findtotal('CREDIT_CARD')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			credittt_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			credittt_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// credittt_fee.value = formatNumberDecimalString(ld[0].totalFee)
			credittt_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			ttfee = ttfee + ld[0].totalFeeReceive
			// ttfee = ttfee + ld[0].totalFee
		}
	}
	ld = findtotal('QR_CODE')
	if (ld.length > 0) {
		if (ld[0].totalReceive !== 0) {
			qrtt_receive.value = formatNumberDecimalString(ld[0].totalReceive)
			ttreceive = ttreceive + ld[0].totalReceive
		}
		if (ld[0].totalPay !== 0) {
			qrtt_pay.value = formatNumberDecimalString(ld[0].totalPay)
			ttpay = ttpay + ld[0].totalPay
		}
		if (ld[0].totalFeeReceive !== 0) {
			// qrtt_fee.value = formatNumberDecimalString(ld[0].totalFee)
			qrtt_fee.value = formatNumberDecimalString(ld[0].totalFeeReceive)
			ttfee = ttfee + ld[0].totalFeeReceive
			// ttfee = ttfee + ld[0].totalFee
		}
	}

	totalreceive.value = formatNumberDecimalString(ttreceive)
	totalpay.value = formatNumberDecimalString(ttpay)
	totalfee.value = formatNumberDecimalString(ttfee)


}


function clearDatasummary() {
	cashtt_receive.value = '';
	cashtt_pay.value = '';
	banktt_receive.value = '';
	banktt_pay.value = '';
	promtmott_receive.value = '';
	promtmott_pay.value = '';
	promttxtt_receive.value = '';
	promttxtt_pay.value = '';
	chequett_receive.value = '';
	chequett_pay.value = '';
	credittt_receive.value = '';
	credittt_pay.value = '';
	cashtt_fee.value = '';
	banktt_fee.value = '';
	promtmott_fee.value = '';
	promttxtt_fee.value = '';
	chequett_fee.value = '';
	credittt_fee.value = '';
	totalpay.value = '';
	totalreceive.value = '';
	totalfee.value = '';
	qrtt_receive.value = '';
	qrtt_pay.value = '';
	qrtt_fee.value = '';
}


function format_datetime(value) {
	if (value) {
		return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
	}
}

function format_date(value) {
	if (value) {
		return moment(String(value)).local().format('DD/MM/YYYY')
	} else {
		return '-'
	}
}

function formatNumberString(num) {
	return String(num).replace(
		/(\d)(?=(\d{3})+(?!\d))/g,
		"$1,"
	);
}

function formatNumberDecimalString(num) {
	if (num !== null && num !== undefined) {
		num = num.toFixed(2)
	} else {
		num = 0
		num = num.toFixed(2)
	}
	return String(num).replace(
		/(\d)(?=(\d{3})+(?!\d))/g,
		"$1,"
	);
}


function checkTransaction(str) {
	// PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
	let side = ''
	if (str === 'PAWN') {
		side = 'จำนำ'
	} else if (str === 'INTEREST') {
		side = 'ต่อดอกเบี้ย'
	} else if (str === 'REDEEM') {
		side = 'ไถ่ถอน'
	} else if (str === 'INC_PRINCIPLE') {
		side = 'เพิ่มต้น'
	} else if (str === 'DEC_PRINCIPLE') {
		side = 'ลดต้น'
	}
	return side
}

function checkProcess(str) {
	let side = ''
	if (str === 'FINISH') {
		side = 'เสร็จสิ้น'
	} else if (str === 'ACTIVE') {
		side = 'กำลังดำเนินการ'
	} else if (str === 'FREEZE') {
		side = 'อายัด'
	} else if (str === 'TERMINATE') {
		side = 'ยกเลิก'
	}
	return side
}

function countTicket(arr) {
	let lp = arr.filter((c) => c.ticket !== null && c.type !== 'PAYMENT');
	return lp.length
}

function findtotal(str) {
	let lp = listsummary.value.filter((c) => c.type === str);
	return lp
}


function filterPayType(pl) {
	payment_l.value = []
	paymentE.value = pl
	let pt = [{ name: 'CASH' },
	{ name: 'BANK_TRANSFER' },
	{ name: 'CREDIT_CARD' },
	{ name: 'CHEQUE' },
	{ name: 'PROMPT_PAY_MOBILE' },
	{ name: 'PROMPT_PAY_TAX_ID' },
	{ name: 'QR_CODE' },
	]

	let lsum = []
	for (let i = 0; i < pt.length; i++) {
		lsum = filterSumPay(pt[i].name)
		if (lsum.length > 0) {
			payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
		} else {
			payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
		}
	}
	// console.log('payment_l : ' + JSON.stringify(payment_l.value));
	return payment_l
}

function filterSumPay(str) {
	// console.log('paymentE : ' + JSON.stringify(paymentE.value));
	let nump = 0, numr = 0, numf = 0
	let lp = paymentE.value.filter((c) => c.type === str);
	if (lp.length > 0) {
		let pay = 0
		let receive = 0
		let fee = 0
		for (let i = 0; i < lp.length; i++) {
			pay = pay + lp[i].pay
			receive = receive + lp[i].receive
			fee = fee + lp[i].feeReceive
			// fee = fee + lp[i].fee
		}
		nump = pay
		numr = receive
		numf = fee
	}
	let lockL = []
	lockL.push({ pay: nump, receive: numr, fee: numf })
	// console.log('lockL : ' + JSON.stringify(lockL));
	return lockL
}


async function openDialogCus() {
	nameThC.value = ''
	nameEnC.value = ''
	docNumberC.value = ''
	cusUid_search.value = ''
	cusName_search.value = ''
	customerlist.value = []
	document.getElementById("searchNameCus").click();
}


async function selectCusAf(item) {
	// console.log('selectCusAf : ' + JSON.stringify(item));
	cusUid_search.value = item.uid
	cusName_search.value = item.nameTh
	document.getElementById("closeModal").click();
}

async function loadCustomerAfListApi() {
	currentPageC.value = 1
	let data = "";
	data = {
		index: currentPageC.value - 1,
		size: perPageC.value,
		nameEn: nameEnC.value,
		nameTh: nameThC.value,
		docNumber: docNumberC.value

	};

	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storecus.searchCustomerFetch(payload);
	// console.log("loadCustomerListApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				let listd = response.data.data.list;
				customerlist.value = listd;
				totalRowsC.value = response.data.data.ext.total
				totalRows_sC.value = formatNumberString(totalRowsC.value)
			}
		}
	}
}

async function updatePagination() {
	listhistory.value = []
	listsummary.value = []
	let data = "";
	data = {
		index: currentPage.value - 1,
		size: perPage.value,
		cusUid: cusUid_search.value,
		fromDate: moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00'),
		toDate: moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00'),
		shopUid: key.uid
	};

	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storep.paymentGroupHistoryFetch(payload);
	// console.log("loadDataApi : " + JSON.stringify(response.data));
	let listd = []
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				clearDatasummary()
				listd = response.data.data.list.paymentGroup;
				listsummary.value = response.data.data.list.summary
				listd.map(function (x) {
					x.payC = 0
					x.receiveC = 0
					x.payB = 0
					x.receiveB = 0
					x.payCC = 0
					x.receiveCC = 0
					x.payCH = 0
					x.receiveCH = 0
					x.payPM = 0
					x.receivePM = 0
					x.payPT = 0
					x.receivePT = 0
					x.fee_cash = 0
					x.fee_bank = 0
					x.fee_promtmo = 0
					x.fee_promttx = 0
					x.fee_cheque = 0
					x.fee_credit = 0
					x.receiveQR = 0
					x.payQR = 0
					x.fee_QR = 0
					return x
				});
				let ld = []
				for (let i = 0; i < listd.length; i++) {
					// console.log(i + ' : ' + JSON.stringify(listd[i]));
					if (listd[i].payments.length > 0) {
						filterPayType(listd[i].payments)
						if (payment_l.value.length > 0) {
							// console.log("payment_l : " + JSON.stringify(payment_l.value));
							listd[i].payC = payment_l.value[0].pay
							listd[i].receiveC = payment_l.value[0].receive
							listd[i].fee_cash = payment_l.value[0].fee
							listd[i].payB = payment_l.value[1].pay
							listd[i].receiveB = payment_l.value[1].receive
							listd[i].fee_bank = payment_l.value[1].fee
							listd[i].payCC = payment_l.value[2].pay
							listd[i].receiveCC = payment_l.value[2].receive
							listd[i].fee_credit = payment_l.value[2].fee
							listd[i].payCH = payment_l.value[3].pay
							listd[i].receiveCH = payment_l.value[3].receive
							listd[i].fee_cheque = payment_l.value[3].fee
							listd[i].payPM = payment_l.value[4].pay
							listd[i].receivePM = payment_l.value[4].receive
							listd[i].fee_promtmo = payment_l.value[4].fee
							listd[i].payPT = payment_l.value[5].pay
							listd[i].receivePT = payment_l.value[5].receive
							listd[i].fee_promttx = payment_l.value[5].fee
							listd[i].payQR = payment_l.value[6].pay
							listd[i].receiveQR = payment_l.value[6].receive
							listd[i].fee_QR = payment_l.value[6].fee
						}

					}
				}
				if (listsummary.value.length > 0) {
					setsummary()
				}
				listhistory.value = listd
				totalRows.value = response.data.data.ext.total
				totalRows_s.value = formatNumberString(totalRows.value)
			}
		}
	}
}


async function updatePaginationC() {
	customerlist.value = []
	let data = "";
	data = {
		index: currentPageC.value - 1,
		size: perPageC.value,
		nameEn: nameEnC.value,
		nameTh: nameThC.value,
		docNumber: docNumberC.value

	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storecus.searchCustomerFetch(payload);
	// console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				customerlist.value = response.data.data.list;
				totalRowsC.value = response.data.data.ext.total
				totalRows_sC.value = formatNumberString(totalRowsC.value)
			}
		}
	}
}


onMounted(() => {
	if (document.getElementById("choices-gender")) {
		var gender = document.getElementById("choices-gender");
		new Choices(gender);
	}

	if (document.getElementById("choices-language")) {
		var language = document.getElementById("choices-language");
		new Choices(language);
	}

	if (document.getElementById("choices-month")) {
		var month = document.getElementById("choices-month");
		setTimeout(function () {
			new Choices(month);
		}, 1);

		// eslint-disable-next-line no-unused-vars
		var d = new Date();
		var monthArray = new Array();
		monthArray[0] = "January";
		monthArray[1] = "February";
		monthArray[2] = "March";
		monthArray[3] = "April";
		monthArray[4] = "May";
		monthArray[5] = "June";
		monthArray[6] = "July";
		monthArray[7] = "August";
		monthArray[8] = "September";
		monthArray[9] = "October";
		monthArray[10] = "November";
		monthArray[11] = "December";
		for (let m = 0; m <= 11; m++) {
			var optn = document.createElement("OPTION");
			optn.text = monthArray[m];
			// server side month start from one\
			optn.value = m + 1;
			// if june selected
			if (m == 1) {
				optn.selected = true;
			}
			month.options.add(optn);
		}
	}

	if (document.getElementById("choices-day")) {
		var day = document.getElementById("choices-day");
		setTimeout(function () {
			new Choices(day);
		}, 1);

		for (let y = 1; y <= 31; y++) {
			var optn1 = document.createElement("OPTION");
			optn1.text = y;
			optn1.value = y;

			if (y == 1) {
				optn1.selected = true;
			}

			day.options.add(optn1);
		}
	}

	if (document.getElementById("choices-year")) {
		var year = document.getElementById("choices-year");
		setTimeout(function () {
			new Choices(year);
		}, 1);

		for (let y = 1900; y <= 2020; y++) {
			var optn2 = document.createElement("OPTION");
			optn2.text = y;
			optn2.value = y;

			if (y == 2020) {
				optn.selected = true;
			}

			year.options.add(optn2);
		}
	}

	if (document.getElementById("choices-skills")) {
		var skills = document.getElementById("choices-skills");
		new Choices(skills, {
			delimiter: ",",
			editItems: true,
			maxItemCount: 5,
			removeItemButton: true,
			addItems: true,
		});
	}
});
</script>

<style lang="scss" scoped>
.review {
	border: 2px solid #1a73e8;
	border-radius: 6px;
	background: #d9e9ff;
}
</style>